import { StoreContext } from '../providers/StoreProvider'
import { useContext } from 'react'
import { UserStore } from '../stores/userStore'

export class Store {
  userStore: UserStore

  constructor() {
    this.userStore = new UserStore(this)
  }
}

export function useStore() {
  const context = useContext(StoreContext)
  if (context === undefined) {
    throw new Error('useStore must be used within StoreProvider')
  }

  return context
}
