import { Table } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import Layout from '../components/Layout'
import { getVestingSubscriptionList } from '../services/vesting.service'
import { truncateAddress } from '../utils/address'

const SubscriptionList = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    handleGetSubscriptionList()
  }, [])

  const handleGetSubscriptionList = async () => {
    try {
      const {
        data: { data },
      } = await getVestingSubscriptionList()
      setData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const columns = [
    {
      title: 'Wallet address',
      dataIndex: 'walletAddress',
      key: 'walletAddress',
      render: (_, record) => (
        <a
          href={`https://etherscan.io/address/${record?.walletAddress}`}
          target="_blank"
          rel="noreferrer"
        >
          {truncateAddress(record?.walletAddress)}
        </a>
      ),
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      render: (_, record) => <span>{record?.token?.symbol}</span>,
    },
    {
      title: 'Symbol icon',
      dataIndex: 'symbolIcon',
      key: 'symbolIcon',
      render: (_, record) => (
        <img
          src={record?.token?.symbolIcon}
          style={{ width: '32px', height: '32px' }}
          alt={record?.token?.symbol}
        />
      ),
    },
    {
      title: 'Coingecko ID',
      dataIndex: 'coingeckoId',
      key: 'coingeckoId',
      render: (_, record) => (
        <a
          href={`https://www.coingecko.com/en/coins/${record?.token?.coingeckoId}`}
          target="_blank"
          rel="noreferrer"
        >
          {record?.token?.coingeckoId}
        </a>
      ),
    },
    {
      title: 'address',
      dataIndex: 'address',
      key: 'address',
      render: (_, record) => (
        <a
          href={`https://etherscan.io/address/${record?.token?.address}`}
          target="_blank"
          rel="noreferrer"
        >
          {truncateAddress(record?.token?.address)}
        </a>
      ),
    },
    {
      title: 'chain',
      dataIndex: 'chain',
      key: 'chain',
      render: (_, record) => <span>{record?.token?.chain?.name}</span>,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <span>{dayjs(record?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
      ),
    },
  ]

  return (
    <>
      <Layout>
        <div
          style={{
            maxWidth: '1500px',
            width: '100%',
            margin: '0 auto',
            padding: '32px 64px',
          }}
        >
          <div style={{ margin: '32px 0' }}>
            <Table
              columns={columns}
              scroll={{ x: 1500 }}
              dataSource={data}
              rowKey="id"
              pagination={{ pageSize: 20 }}
            />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default SubscriptionList
