import { Table } from 'antd'

export const AllocationTable = (props) => {
  const { datasource } = props

  const columns = [
    {
      title: 'Group Name',
      dataIndex: 'groupName',
      key: 'groupName',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Group Name Check',
      dataIndex: 'groupNameCheck',
      key: 'groupNameCheck',
    },
    {
      title: 'TokenId',
      dataIndex: 'tokenId',
      key: 'tokenId',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Group Priority',
      dataIndex: 'groupPriority',
      key: 'groupPriority',
    },
    {
      title: 'Chart Priority',
      dataIndex: 'chartPriority',
      key: 'chartPriority',
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ]

  return <Table dataSource={datasource} columns={columns} />
}
