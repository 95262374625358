import {
  Button,
  Col,
  Drawer,
  DrawerProps,
  Form,
  Input,
  notification,
  Row,
  Space,
} from 'antd'
import { useCallback, useEffect } from 'react'
import { createCategory } from '../../services/category.service'

interface CategoryCreateDrawerProps extends DrawerProps {}

const CategoryCreateDrawer = (props: CategoryCreateDrawerProps) => {
  const { visible, onClose, ...rest } = props

  const [form] = Form.useForm()

  const handleSubmit = useCallback(async () => {
    try {
      const { name } = form.getFieldsValue()
      const payload = { name }

      await createCategory(payload)
      notification.success({ message: `Saved` })

      onClose(null)
    } catch (error) {
      notification.error({ message: 'Something went wrong' })
    }
  }, [form, onClose])

  useEffect(() => {
    form.resetFields()
  }, [form, visible])

  return (
    <Drawer
      title="Create category"
      width={720}
      onClose={onClose}
      visible={visible}
      extra={
        <Space>
          <Button onClick={onClose as () => void}>Cancel</Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </Space>
      }
      {...rest}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please enter category name' }]}
            >
              <Input placeholder="Please enter category name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  )
}

export default CategoryCreateDrawer
