import { ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from '../stores'

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { userStore } = useStore()
  const navigate = useNavigate()

  useEffect(() => {
    if (userStore.user && !['outsource', 'admin'].includes(userStore.user.role)) {
      sessionStorage.removeItem('credential')
      userStore.setUser(null)
      navigate('/login')
    }
  }, [userStore, navigate])

  return <div>{children}</div>
}
