import { Table } from 'antd'

export const RoleTable = (props) => {
  const { datasource } = props

  const columns = [
    {
      title: 'Wallet Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
    },
  ]

  return <Table dataSource={datasource} columns={columns} />
}
