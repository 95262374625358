import { Form, Input, Modal, ModalFuncProps, notification } from 'antd'
import TextArea from 'antd/lib/input/TextArea'

import { useParams } from 'react-router-dom'

import { createUtility } from '../../../services/utility.service'

const UtilityCreateModal = (props: ModalFuncProps) => {
  const { onOk, onCancel, ...rest } = props
  const { tokenId } = useParams()
  const [form] = Form.useForm()

  const onFinish = async (values: any) => {
    try {
      const payload = {
        ...values,
      }

      await createUtility(payload)
      notification.success({ message: `created ${form.getFieldValue('name')}` })
      onOk()
    } catch (error) {
      notification.error({ message: 'Something went wrong' })
    }
  }

  return (
    <>
      <Modal
        title="Create Utility"
        onOk={() => {
          form.submit()
        }}
        onCancel={onCancel}
        {...rest}
      >
        <div>
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ tokenId, remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Token Id"
              name="tokenId"
              rules={[{ required: true, message: 'Please input your tokenId!' }]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Please input your description!' }]}
            >
              <TextArea rows={2} />
            </Form.Item>

            <Form.Item
              label="Url"
              name="url"
              rules={[{ required: true, message: 'Please input your url!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Icon"
              name="icon"
              rules={[{ required: true, message: 'Please input your Icon!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Disclaimer"
              name="disclaimer"
              rules={[{ message: 'Please input your disclaimer!' }]}
            >
              <TextArea rows={2} />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      {/* <Layout>
        <div
          style={{
            maxWidth: '1200px',
            width: '100%',
            margin: '0 auto',
            padding: '32px 64px',
          }}
        ></div>
      </Layout> */}
    </>
  )
}

export default UtilityCreateModal
