import { notification } from "antd";
import dayjs from "dayjs";
import { convertToDate } from "./convert-date";

export const checkEndDate = (startDate: string, endDate: string, sheetName: string, index: number) => {
    const beginD = startDate
      ? dayjs(convertToDate(startDate))
      : ''
    const endD =  endDate
      ?endDate != '-'
        ? dayjs(convertToDate(endDate))
        : ''
      : ''
    if (beginD !== "" && endD !== "" &&  endD < beginD) {
      notification.error({ message: `End date is before begin date ${sheetName} at index ${index}` });
    }
  }