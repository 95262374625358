import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(customParseFormat)

export const convertToDate = (str: string) => {
  const newDate = dayjs.utc(str, 'DD/MM/YYYY HH:mm:ss')
  return newDate
}
