import { IUser } from './../interfaces/user.interface'
import { makeAutoObservable } from 'mobx'
import { Store } from '../stores'

export class UserStore {
  root: Store
  user: IUser | null

  constructor(root: Store) {
    this.root = root
    makeAutoObservable(this)
  }

  setUser = (user: IUser | null) => {
    this.user = user
  }
}
