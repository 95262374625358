import { List, notification, Spin, Table, Tabs } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Row, Space } from 'antd'
import Layout from '../../../components/Layout'
import csv from 'csvtojson'
import {
  createTokenLink,
  getTokenById,
  updateTokens,
} from '../../../services/token.service'
import * as XLSX from 'xlsx'
import { usePapaParse } from 'react-papaparse'
import { worksheetConfig } from '../../../configs/worksheet.config'
import {
  createVesting,
  generateChartData,
  generatePreviewData,
} from '../../../services/vesting.service'
import dayjs from 'dayjs'
import { convertToDate } from '../../../utils/convert-date'
import { createAllocation } from '../../../services/allocation.service'
import { getCurrentBlockInfo } from '../../../utils/ethereum'
import { AllocationTable } from './AllocationTable'
import { RoleTable } from './RoleTable'
import { ReferencesTable } from './ReferenceTable'
import ConfirmModal from './ConfirmModal'
import PreviewChartDataModal from './PreviewChartDataModal'
import { PreviewChart } from './PreviewChart'
import { groupAllocationColor } from '../../../utils/group'
import { checkEndDate } from '../../../utils/asserts'
import {
  TokenUpdate,
  Allocation,
  Role,
  Vesting,
  Reference,
} from '../../../types/vesting'

enum PreviewTab {
  Vestings = 'vestings',
  Allocations = 'allocations',
  Roles = 'roles',
  References = 'refferences',
}

const { TabPane } = Tabs

const VestingTable = (props) => {
  const { datasource } = props

  const columns = [
    {
      title: 'Begin Date',
      dataIndex: 'beginDate',
      key: 'beginDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Start Block',
      dataIndex: 'startBlock',
      key: 'startBlock',
    },
    {
      title: 'End Block',
      dataIndex: 'endBlock',
      key: 'endBlock',
    },
    {
      title: 'beginAmount',
      dataIndex: 'beginAmount',
      key: 'beginAmount',
    },
    {
      title: 'endAmount',
      dataIndex: 'endAmount',
      key: 'endAmount',
    },
  ]

  return <Table dataSource={datasource} columns={columns} />
}

const UploadOldVesting = () => {
  const navigate = useNavigate()
  const { readString } = usePapaParse()
  const [tokenUpdate, setTokenUpdate] = useState<TokenUpdate>({})
  const [loading, setIsLoading] = useState<boolean>(false)
  const [vestings, setVestings] = useState<any>()

  const [allocations, setAllocations] = useState<Allocation[]>([])
  const [roles, setRoles] = useState<Role[]>([])
  const [references, setReferences] = useState<Role[]>([])
  const [coingekcoId, setCoingekcoId] = useState<string>('')

  const [chartData, setChartData] = useState<any>(null)

  const changeHandler = async (event) => {
    const [file] = event.target.files

    const reader = new FileReader()

    reader.onload = async (evt) => {
      console.log('reading...')
      const bstr = evt.target.result

      const workbook = XLSX.read(bstr, { type: 'binary' })

      let vestingData = {}
      let updateData: TokenUpdate = {
        id: '',
        description: '',
      }
      let tokenInfo = {
        id: '',
      }

      // So if you want to read multiple sheets you can use the list of sheet names (workbook.SheetNames) and the sheets object (workbook.Sheets). I would use a for-loop to go through each sheet name in the SheetNames array, then access the Sheets object by passing in the sheet name to get the sheet.
      // for (let i = 0; i < workbook.SheetNames.length; ++i) {
      for await (const sheetName of workbook.SheetNames) {
        const sheet = workbook.Sheets[sheetName]
        const data = XLSX.utils.sheet_to_csv(sheet)

        const results = await csv({
          noheader: true,
          output: 'csv',
        }).fromString(data)

        try {
          // Working with general info
          if (sheetName === 'Project') {
            console.log('project')

            const coingekcoId = results[5][1]
            console.log('coingekcoId: ', coingekcoId)
            setCoingekcoId(coingekcoId)

            const { data } = await getTokenById(coingekcoId)

            const token = data.data

            console.log('token: ', token)

            tokenInfo.id = token.id

            updateData.id = token.id

            await results.map((walletInfo, index) => {
              if (index === worksheetConfig.token.description) {
                updateData.description = walletInfo[1]
              }
              if (index >= worksheetConfig.labelIndex && walletInfo[0]) {
                const constructData = {
                  group: walletInfo[1],
                  tokenId: token.id,
                  chainId: walletInfo[12],
                  address: walletInfo[2],
                  unclaimAmount: '0',
                  amount: walletInfo[7].replace(/\,/g, ''),
                  label: walletInfo[0],
                  registerType: walletInfo[3],
                  supplyType: walletInfo[5],
                  dataSource: walletInfo[11],
                  walletType: walletInfo[4],
                  vestingType: walletInfo[6],
                  tx: walletInfo[10],
                  beginDate: '',
                  endDate: '',
                  startBlock: 0,
                  endBlock: 0,
                  cards: [],
                  claims: [],
                  roles: [],
                }
                vestingData[walletInfo[0]] = constructData
              }
            })
          }
          // Working with vesting info
          if (sheetName === 'Vesting') {
            console.log('vesting')

            await results.map((vesting, index) => {
              if (index >= worksheetConfig.vestingStartIndex) {
                if (vesting[0]) {
                  checkEndDate(vesting[1], vesting[2], sheetName, index);
                  const constructData = {
                    beginAmount: vesting[5].replace(/\,/g, ''),
                    endAmount: vesting[6].replace(/\,/g, ''),
                    beginDate: vesting[1]
                      ? dayjs(convertToDate(vesting[1])).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                      : '',
                    endDate: vesting[2]
                      ? dayjs(convertToDate(vesting[2])).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                      : '',
                    startBlock: +vesting[3].replace(/\,/g, '')
                      ? +vesting[3].replace(/\,/g, '')
                      : 0,
                    endBlock: +vesting[4].replace(/\,/g, '')
                      ? +vesting[4].replace(/\,/g, '')
                      : 0,
                  }
                  if (vestingData[vesting[0]]) {
                    vestingData[vesting[0]].cards = [
                      ...vestingData[vesting[0]].cards,
                      constructData,
                    ]
                  }
                }
              }
            })

            // ReconstructData
            Object.values(vestingData).map((vestingItem: Vesting, index) => {
              vestingData[vestingItem.label] = {
                ...vestingData[vestingItem.label],
                beginDate: vestingData[vestingItem.label].cards[0]?.beginDate ?? '',
                endDate:
                  vestingData[vestingItem.label].cards[
                    vestingData[vestingItem.label].cards.length - 1
                  ]?.endDate ?? '',
                startBlock:
                  +vestingData[vestingItem.label].cards[0]?.startBlock ?? 0,
                endBlock:
                  +vestingData[vestingItem.label].cards[
                    vestingData[vestingItem.label].cards.length - 1
                  ]?.endBlock ?? 0,
              }
            })

            setTokenUpdate(updateData)
            setVestings(vestingData)
          }

          // Working with allocation info
          if (sheetName === 'Allocation') {
            let allocationData = []

            results.map((allocation, index) => {
              if (
                index >= worksheetConfig.allocationStartIndex &&
                allocation[0].length !== 0
              ) {
                const constructData: Allocation = {
                  tokenId: tokenInfo.id,
                  groupName: allocation[0],
                  groupNameCheck: true,
                  amount: allocation[1].replace(/\,/g, ''),
                  status: true,
                  chartPriority: +allocation[6].replace(/\,/g, ''),
                  groupPriority: +allocation[5].replace(/\,/g, ''),
                  color: allocation[4],
                }
                allocationData.push(constructData)
              }
            })
            setAllocations(allocationData)
          }

          // Working with Roles info
          if (sheetName === 'Roles') {
            let rolesData = []

            results.map((role, index) => {
              if (index >= worksheetConfig.rolesStartIndex && role[0].length !== 0) {
                const constructData: Role = {
                  name: role[1],
                  address: role[2],
                  role: role[3],
                  group: role[0],
                }
                rolesData.push(constructData)
              }
            })
            setRoles(rolesData)
          }

          // Working with Roles info
          if (sheetName === 'References') {
            let referencesData = []

            results.map((ref, index) => {
              if (index >= worksheetConfig.refsStartIndex && ref[0].length !== 0) {
                const constructData: Reference = {
                  label: ref[0],
                  url: ref[1],
                  type: ref[2],
                }
                referencesData.push(constructData)
              }
            })
            setReferences(referencesData)
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
    reader.readAsBinaryString(file)
  }

  const onConfirmVesting = async () => {
    try {
      setIsLoading(true)
      if (vestings) {
        const vestingsArray = Object.values(vestings).map(
          (vesting: Vesting, index) => {
            return {
              ...vesting,
              roles: roles.filter((role) => role.group === vesting.label),
            }
          }
        )

        const currentBlockInfo = await getCurrentBlockInfo()
        const vestingsPayload = {
          data: vestingsArray,
          currentBlockInfo: currentBlockInfo,
        }
        const allocationsPayload = { data: allocations }
        const updateTokensPayload = { data: [tokenUpdate] }
        const referencesPayload = {
          data: references.map((ref, index) => ({
            ...ref,
            tokenId: tokenUpdate.id,
          })),
        }

        console.log('updateTokens payload', updateTokensPayload)
        console.log('allocation payload', allocationsPayload)
        console.log('vesting payload', vestingsPayload)
        console.log('references payload', referencesPayload)

        const resultUpdateToken = await updateTokens(updateTokensPayload)
        const resultVesting = await createVesting(vestingsPayload)
        const resultAllocation = await createAllocation(allocationsPayload)
        const resultReference = await createTokenLink(referencesPayload)

        generateChartData(coingekcoId)
        console.log('generating chart data')

        console.log('result vesting: ', resultVesting)
        console.log('result update: ', resultUpdateToken)
        console.log('result allocation: ', resultAllocation)

        console.log('result reference: ', resultReference)
        setIsLoading(false)
        notification.success({ message: `Complete!` })
      }

      //   navigate('/')
    } catch (error) {
      console.log('error: ', error)
      setIsLoading(false)
      notification.error({ message: 'Something went wrong' })
    }
  }

  const onConfirmPreview = async (period: string) => {
    try {
      if (vestings) {
        setIsLoading(true)
        const vestingsArray = Object.values(vestings).map(
          (vesting: Vesting, index) => {
            return {
              ...vesting,
              roles: roles.filter((role) => role.group === vesting.group),
            }
          }
        )

        const currentBlockInfo = await getCurrentBlockInfo()
        const vestingsPayload = {
          data: vestingsArray,
          currentBlockInfo: currentBlockInfo,
        }

        const {
          data: { chartData },
        } = await generatePreviewData({
          ...vestingsPayload,
          step: period,
        })

        setChartData(chartData)
        setIsLoading(false)
      }
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const chart = useMemo(() => chartData, [chartData])

  const VestingPreview = useMemo(() => {
    if (vestings) {
      return (
        <>
          <div
            style={{
              display: 'flex',
              padding: '20px',
              justifyContent: 'space-between',
            }}
          >
            <h2>Preview Detail</h2>
            <div>
              <span>
                <PreviewChartDataModal
                  disabledButton={!vestings || allocations.length === 0}
                  onConfirm={onConfirmPreview}
                  loading={loading}
                />
              </span>
              <span style={{ marginLeft: '8px' }}>
                <ConfirmModal
                  disabledButton={!vestings || allocations.length === 0}
                  onConfirm={onConfirmVesting}
                  loading={loading}
                />
              </span>
            </div>
            {/* <Button onClick={onConfirmVesting}>Confirm Create Vesting</Button> */}
          </div>

          <Tabs style={{ padding: '0 20px' }} defaultActiveKey={PreviewTab.Vestings}>
            <TabPane tab="Vestings" key={PreviewTab.Vestings}>
              <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                {Object.values(vestings).map((vesting: Vesting, index) => {
                  const vestingCards = vesting.cards.map((detail, index) => {
                    return {
                      key: index,
                      beginAmount: detail.beginAmount ? detail.beginAmount : '-',
                      endAmount: detail.endAmount ? detail.endAmount : '-',
                      beginDate: detail.beginDate ? detail.beginDate : '-',
                      endDate: detail.endDate ? detail.endDate : '-',
                      startBlock: detail.startBlock ? detail.startBlock : '-',
                      endBlock: detail.endBlock ? detail.endBlock : '-',
                    }
                  })

                  return (
                    <Card
                      key={vesting.label}
                      headStyle={{ background: '#1890ff', color: 'white' }}
                      bodyStyle={{ background: '#f0f0f0' }}
                      title={`${vesting.label}`}
                      bordered={false}
                    >
                      <h4>General Information</h4>
                      <Row>
                        {Object.keys(vesting).map((key, index) => {
                          if (!Array.isArray(vesting[key])) {
                            return (
                              <Col span={8}>
                                <List.Item>{`${key}: ${
                                  vesting[key] ? vesting[key] : '-'
                                }`}</List.Item>
                              </Col>
                            )
                          }
                        })}
                      </Row>
                      <VestingTable datasource={vestingCards} />
                    </Card>
                  )
                })}
              </Space>
            </TabPane>
            <TabPane tab="Allocations" key={PreviewTab.Allocations}>
              <AllocationTable datasource={allocations} />
            </TabPane>
            <TabPane tab="Roles" key={PreviewTab.Roles}>
              <RoleTable datasource={roles} />
            </TabPane>
            <TabPane tab="References" key={PreviewTab.References}>
              <ReferencesTable datasource={references} />
            </TabPane>
          </Tabs>
        </>
      )
    }
  }, [vestings, allocations, roles, references])

  return (
    <>
      <Layout>
        <input
          type="file"
          name="file"
          onChange={changeHandler}
          style={{ display: 'block', margin: '10px auto' }}
        />
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spin />
          </div>
        )}
        {vestings && !loading && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '768px', height: '400px' }}>
              <PreviewChart
                data={chart ? chart : []}
                colors={
                  allocations && chart
                    ? groupAllocationColor(allocations, chart[0])
                    : []
                }
              />
            </div>
          </div>
        )}
        {VestingPreview}
      </Layout>
    </>
  )
}

export default UploadOldVesting
