import fetch from '../utils/fetch'

export const getTokenList = () => {
  let method = 'GET'
  let path = `token/list/raw`
  return fetch(method, path)
}

// export const getTokenByCoingeckoId = (id: string) => {
//   let method = 'GET'
//   let path = `token/private/${id}`
//   return fetch(method, path)
// }

export const getTokenById = (coingeckoId: string) => {
  let method = 'POST'
  let path = `token/${coingeckoId}`
  return fetch(method, path)
}

export const createToken = (payload: any) => {
  let method = 'POST'
  let path = `token`
  return fetch(method, path, payload)
}

export const createTokenLink = (payload: any) => {
  let method = 'PUT'
  let path = `token/link/list`
  return fetch(method, path, payload)
}

export const updateToken = (id: string, payload: any) => {
  let method = 'PATCH'
  let path = `token/${id}`
  return fetch(method, path, payload)
}

export const updateTokens = (payload: any) => {
  let method = 'PATCH'
  let path = `token/list`
  return fetch(method, path, payload)
}

export const deleteToken = (id: string) => {
  let method = 'Delete'
  let path = `token/${id}`
  return fetch(method, path)
}
