import fetch from '../utils/fetch'

export const getCategoryList = () => {
  let method = 'GET'
  let path = `token/category/list`
  return fetch(method, path)
}

export const getTokenListByCategory = (categoryId: string) => {
  let method = 'GET'
  let path = `token/category/${categoryId}`
  return fetch(method, path)
}

export const updateCategory = (categoryId: string, payload: { name: string }) => {
  let method = 'PATCH'
  let path = `token/category/${categoryId}`
  return fetch(method, path, payload)
}

export const updateCategoryTokens = (
  categoryId: string,
  payload: { data: string[] }
) => {
  let method = 'PUT'
  let path = `token/category-token/${categoryId}`
  return fetch(method, path, payload)
}

export const updateCategoryPriority = (payload: { data: string[] }) => {
  let method = 'PUT'
  let path = `token/category/priority/sync`
  return fetch(method, path, payload)
}

export const createCategory = (payload: { name: string }) => {
  let method = 'POST'
  let path = `token/category`
  return fetch(method, path, payload)
}
