import { Menu, Breadcrumb, Layout as AntdLayout, MenuProps } from 'antd'
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { ReactNode, useState } from 'react'
import Head from '../Head'
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../stores'

interface Props {
  children?: ReactNode
}

const { Header, Content, Footer, Sider } = AntdLayout
type MenuItem = Required<MenuProps>['items'][number]
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem
}

const items: MenuItem[] = [
  getItem('Token', 'token', <UserOutlined />, [
    getItem('List', 'token/list'),
    getItem('Create', 'token/create'),
  ]),
  getItem('Chain', 'chain', <UserOutlined />, [
    getItem('Chain List', 'chain/list'),
    getItem('Create', 'chain/create'),
  ]),
  getItem('Category', 'category', <UserOutlined />, [
    getItem('List', 'category/list'),
  ]),
  getItem('Utility', 'utility', <UserOutlined />, [getItem('List', 'utility/list')]),
  getItem('Vesting', 'vesting', <TeamOutlined />, [getItem('List', 'list')]),
  getItem('Investor', 'investor', <TeamOutlined />, [
    getItem('Investor List', 'investor/list'),
    getItem('Create', 'investor/create'),
  ]),
  getItem('Upload', 'upload', <FileOutlined />, [
    getItem('Old Vesting', 'upload/upload-old-vesting'),
    getItem('V1 Vesting', 'upload/upload-vesting-v1'),
    getItem('V2 Vesting', 'upload/upload-vesting-v2'),
  ]),
  getItem('Tooling', 'tools', <FileOutlined />, [
    getItem('Gen Vesting', 'tools/gen-vesting'),
  ]),
]

const Layout = ({ children }: Props) => {
  const [collapsed, setCollapsed] = useState(false)
  const { userStore } = useStore()
  const navigate = useNavigate()
  const onClick: MenuProps['onClick'] = (e) => {
    navigate(`/${e.key}`)
  }
  return (
    <AntdLayout style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Menu
          onClick={onClick}
          theme="dark"
          defaultSelectedKeys={['1']}
          mode="inline"
          items={items}
        />
      </Sider>
      <AntdLayout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,

            color: 'white',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ marginRight: 10 }}>
            Login with ${userStore.user?.firstName} ${userStore.user?.lastName}
          </div>
        </Header>
        <Content style={{ margin: '0 16px' }}>{children}</Content>
      </AntdLayout>
    </AntdLayout>
  )
}

export default Layout
