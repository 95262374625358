import {
  Button,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  notification,
  Popconfirm,
  Popover,
  Table,
} from 'antd'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Layout from '../components/Layout'
import {
  clearFrontendDataCache,
  createVestingRelease,
  deleteVesting,
  deleteVestingRelease,
  getVestingList,
} from '../services/vesting.service'
import { truncateAddress } from '../utils/address'

const Home = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const [visible, setVisible] = useState(false)
  const [data, setData] = useState([])
  const [drawerData, setDrawerData] = useState<any>({})
  const [keyword, setKeyword] = useState<string>('')

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setDrawerData({})
    setVisible(false)
    handleGetVestingList()
  }

  useEffect(() => {
    handleGetVestingList()
  }, [])

  const handleGetVestingList = async () => {
    try {
      const {
        data: { data },
      } = await getVestingList()
      setData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handlePageCreateVesting = () => {
    navigate('/vesting')
  }

  const columns = [
    {
      title: 'Chain',
      dataIndex: 'chain',
      key: 'chain',
      render: (_, record) => <span>{record?.token?.chain?.name}</span>,
    },
    {
      title: 'Token',
      dataIndex: 'token',
      key: 'token',
      render: (_, record) => <span>{record?.token?.name}</span>,
    },
    {
      title: 'Protocol',
      dataIndex: 'protocol',
      key: 'protocol',
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Begin Date',
      dataIndex: 'beginDate',
      key: 'beginDate',
      render: (_, record) => (
        <Popover content={<div>{record?.beginDate}</div>}>
          {dayjs.unix(Number(record?.beginDate) / 1000).format('YYYY-MM-DD')}
        </Popover>
      ),
    },
    {
      title: 'Cliff',
      dataIndex: 'cliffInMonth',
      key: 'cliffInMonth',
      render: (_, record) => (
        <Popover content={<div>{record?.cliffInMonth} Month(s)</div>}>
          {dayjs
            .unix(Number(record?.beginDate) / 1000)
            .add(Number(record?.cliffInMonth), 'months')
            .format('YYYY-MM-DD')}
        </Popover>
      ),
    },
    {
      title: 'Full release',
      dataIndex: 'fullReleaseInMonth',
      key: 'fullReleaseInMonth',
      render: (_, record) => (
        <Popover content={<div>{record?.fullReleaseInMonth} Month(s)</div>}>
          {dayjs
            .unix(Number(record?.beginDate) / 1000)
            .add(Number(record?.fullReleaseInMonth), 'months')
            .format('YYYY-MM-DD')}
        </Popover>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => <span>{Number(record?.amount)?.toFixed(2)}</span>,
    },
    {
      title: 'Released',
      dataIndex: 'released',
      key: 'released',
      render: (_, record) => (
        <span>
          {Number(
            record?.releases?.reduce((sum, e) => +sum + +e?.amount, 0)
          )?.toFixed(2)}
        </span>
      ),
    },
    {
      title: 'Unclaim Amount',
      dataIndex: 'unClaimAmount',
      key: 'unClaimAmount',
      render: (_, record) => (
        <span>{Number(record?.unClaimAmount)?.toFixed(2)}</span>
      ),
    },
    {
      title: 'Contract Address',
      dataIndex: 'contractAddress',
      key: 'contractAddress',
      render: (_, record) => (
        <a
          href={`https://etherscan.io/address/${record?.contractAddress}`}
          target="_blank"
          rel="noreferrer"
        >
          {truncateAddress(record?.contractAddress)}
        </a>
      ),
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      render: (_, record) => (
        <a
          href={`https://etherscan.io/address/${record?.owner}`}
          target="_blank"
          rel="noreferrer"
        >
          {truncateAddress(record?.owner)}
        </a>
      ),
    },
    {
      title: 'Tx',
      dataIndex: 'tx',
      key: 'tx',
      render: (_, record) => (
        <a
          href={`https://etherscan.io/tx/${record?.tx}`}
          target="_blank"
          rel="noreferrer"
        >
          {truncateAddress(record?.tx)}
        </a>
      ),
    },
    {
      title: 'Wallet type',
      dataIndex: 'walletType',
      key: 'walletType',
    },
    {
      title: 'Unlock date type',
      dataIndex: 'unlockDateType',
      key: 'unlockDateType',
    },
    {
      title: 'Vesting Owner',
      dataIndex: 'vestingOwner',
      key: 'vestingOwner',
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
    },
    {
      title: '',
      dataIndex: 'view',
      key: 'view',
      render: (_, record) => (
        <span
          style={{ cursor: 'pointer', color: '#1890ff' }}
          onClick={() => {
            setDrawerData(record)
            showDrawer()
          }}
        >
          View
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => <a href={`/vesting/${record?.id}`}>Edit</a>,
    },
    {
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      render: (_, record) => {
        if (record?.label !== 'Auto') {
          return (
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => handleRemoveVesting(record?.id)}
              okText="Yes"
              cancelText="No"
            >
              <span
                style={{
                  marginRight: '8px',
                  color: 'red',
                  cursor: 'pointer',
                }}
              >
                Delete
              </span>
            </Popconfirm>
          )
        }
      },
    },
  ]

  const handleRemoveVesting = async (id: string) => {
    try {
      await deleteVesting(id)
      handleGetVestingList()
      notification.success({ message: `delete ${id}` })
    } catch (error) {
      console.error(error)
    }
  }

  const onFinish = async (values: any) => {
    try {
      const payload = {
        vestingId: drawerData?.id?.toString(),
        amount: values?.amount?.toString(),
        createdAt: dayjs(values?.createdAt)?.valueOf(),
      }

      await createVestingRelease(payload)

      setDrawerData({
        ...drawerData,
        releases: [...drawerData?.releases, payload],
      })

      form.resetFields()

      notification.success({ message: `created release` })
    } catch (error) {
      console.error(error)
    }
  }

  const handleRemove = async (id: string) => {
    await deleteVestingRelease(id)

    setDrawerData({
      ...drawerData,
      releases: drawerData?.releases?.filter((e) => e?.id !== id),
    })

    notification.success({ message: `deleted release` })
  }

  const handleClearCache = async () => {
    try {
      await clearFrontendDataCache()
      notification.success({ message: 'clear frontend data cache' })
    } catch (error) {
      console.error(error)
    }
  }

  const getVestingData = useCallback(() => {
    if (keyword?.length) {
      return data?.filter((t) => {
        return (
          t?.protocol?.toLocaleLowerCase().indexOf(keyword?.toLocaleLowerCase()) !==
            -1 ||
          t?.token?.name
            ?.toLocaleLowerCase()
            .indexOf(keyword?.toLocaleLowerCase()) !== -1 ||
          t?.label?.toLocaleLowerCase().indexOf(keyword?.toLocaleLowerCase()) !==
            -1 ||
          t?.token?.chain?.name
            ?.toLocaleLowerCase()
            .indexOf(keyword?.toLocaleLowerCase()) !== -1
        )
      })
    }
    return data
  }, [keyword, data])

  return (
    <>
      <Layout>
        <div
          style={{
            maxWidth: '1500px',
            width: '100%',
            margin: '0 auto',
            padding: '32px 64px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <Button type="primary" onClick={handlePageCreateVesting}>
              Create Vesting
            </Button>
            <div style={{ marginLeft: '8px' }}>
              <Popconfirm
                title="Are you sure?"
                onConfirm={handleClearCache}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" danger>
                  Clear Vesting Cache
                </Button>
              </Popconfirm>
            </div>
            <div style={{ marginLeft: '8px', display: 'flex' }}>
              <div style={{ marginRight: '8px', width: '300px' }}>
                <Input
                  value={keyword}
                  placeholder="Search by protocol or token or chain"
                  onChange={(e) => setKeyword(e?.target?.value)}
                />
              </div>
            </div>
          </div>

          <div style={{ margin: '32px 0' }}>
            <Table
              columns={columns}
              scroll={{ x: 1500 }}
              dataSource={getVestingData()}
              rowKey="id"
              pagination={{ pageSize: 20 }}
            />
          </div>
        </div>
        <Drawer
          title={drawerData?.protocol}
          placement="right"
          onClose={onClose}
          visible={visible}
          destroyOnClose
        >
          <div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Protocol: </span>
              <span>{drawerData?.protocol}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Label: </span>
              <span>{drawerData?.label}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Begin Date: </span>
              <span>{drawerData?.beginDate}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Cliff: </span>
              <span>{drawerData?.cliffInMonth} Month(s)</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Full release: </span>
              <span>{drawerData?.fullReleaseInMonth} Month(s)</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Amount: </span>
              <span>{Number(drawerData?.amount)?.toFixed(2)}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Unclaim amount: </span>
              <span>{Number(drawerData?.unClaimAmount)?.toFixed(2)}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Contracta address: </span>
              <span>
                <a
                  href={`https://etherscan.io/address/${drawerData?.contractAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {truncateAddress(drawerData?.contractAddress)}
                </a>
              </span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Owner: </span>
              <span>
                <a
                  href={`https://etherscan.io/address/${drawerData?.owner}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {truncateAddress(drawerData?.owner)}
                </a>
              </span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Tx: </span>
              <span>
                <a
                  href={`https://etherscan.io/tx/${drawerData?.tx}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {truncateAddress(drawerData?.tx)}
                </a>
              </span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Wallet type: </span>
              <span>{drawerData?.walletType}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Unlock date type: </span>
              <span>{drawerData?.unlockDateType}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Vesting owner: </span>
              <span>{drawerData?.vestingOwner}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Remark: </span>
              <span>{drawerData?.remark}</span>
            </div>
          </div>
          <Divider />
          <div style={{ width: '100%' }}>
            <h3>Create release</h3>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8, md: { span: 8 } }}
              wrapperCol={{ span: 8 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Created at"
                name="createdAt"
                rules={[{ required: true, message: 'Please input your createdAt!' }]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                label="Amount"
                name="amount"
                rules={[{ required: true, message: 'Please input your amount!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                wrapperCol={{ offset: 0, span: 24, md: { offset: 8, span: 8 } }}
              >
                <Button type="primary" htmlType="submit" block>
                  Create
                </Button>
              </Form.Item>
            </Form>
          </div>
          <Divider />
          <div>
            <h3>Released List</h3>
            {drawerData?.releases
              ?.sort((a, b) => b?.createdAt - a?.createdAt)
              .map((release) => {
                return (
                  <div key={release?.id} style={{ marginBottom: '8px' }}>
                    <div style={{ fontWeight: 'bold' }}>
                      <Popconfirm
                        title="Are you sure to delete this release?"
                        onConfirm={() => handleRemove(release?.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <span
                          style={{
                            marginRight: '8px',
                            color: 'red',
                            cursor: 'pointer',
                          }}
                        >
                          Delete
                        </span>
                      </Popconfirm>
                      <span>
                        {dayjs.unix(release?.createdAt / 1000)?.format('YYYY-MM-DD')}
                      </span>
                    </div>
                    <div>
                      {Number(release?.amount)?.toFixed(2)}{' '}
                      {drawerData?.token?.symbol}
                    </div>
                  </div>
                )
              })}
          </div>
        </Drawer>
      </Layout>
    </>
  )
}

export default Home
