import { BigNumber, Bytes, ContractFactory, Signer, providers } from 'ethers'
import * as ethers from 'ethers'

export const MAX_UINT256 = ethers.constants.MaxUint256
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export enum TIME {
  SECONDS = 1,
  DAYS = 86400,
  WEEKS = 604800,
}

// DEPLOYMENT helper functions

export async function getCurrentBlock() {
  const provider = new ethers.providers.JsonRpcProvider(
    'https://cloudflare-eth.com/'
  )
  const currentBlock = await provider.getBlockNumber()
  return currentBlock
}

export async function getCurrentTimeStamp() {
  const provider = new ethers.providers.JsonRpcProvider(
    'https://cloudflare-eth.com/'
  )
  const currentBlock = await provider.getBlockNumber()
  const currentBlockInfo = await provider.getBlock(currentBlock)

  return currentBlockInfo.timestamp
}

export async function getCurrentBlockInfo() {
  const provider = new ethers.providers.JsonRpcProvider(
    'https://cloudflare-eth.com/'
  )
  const currentBlock = await provider.getBlockNumber()
  const currentBlockInfo = await provider.getBlock(currentBlock)

  return {
    currentTimestamp: currentBlockInfo.timestamp.toString(),
    currentBlock: currentBlock.toString(),
  }
}
