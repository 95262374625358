import { Button, Modal, Select, Spin } from 'antd'
import React, { useState } from 'react'

type Props = {
  onConfirm: any
  loading: boolean
  disabledButton: boolean
}

export const PreviewChartDataModal = (props: Props) => {
  const { onConfirm, loading, disabledButton } = props
  const [period, setPeriod] = useState('months')

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    onConfirm(period)
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleChange = (value: any) => {
    setPeriod(value)
  }

  return (
    <>
      <Button disabled={disabledButton} type="primary" onClick={showModal}>
        Preview Chart
      </Button>
      <Modal
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: loading }}
      >
        {loading ? (
          <Spin />
        ) : (
          <div>
            <span style={{ marginRight: '8px' }}>Period</span>
            <Select
              defaultValue="auto"
              style={{ width: 120 }}
              onChange={handleChange}
            >
              <Select.Option value="auto">Auto</Select.Option>
            </Select>
          </div>
        )}
      </Modal>
    </>
  )
}

export default PreviewChartDataModal
