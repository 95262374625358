import { Button, Form, Input, notification } from 'antd'

import { useNavigate } from 'react-router-dom'

import Layout from '../../../components/Layout'
import { createToken } from '../../../services/token.service'

const EditToken = () => {
  const navigate = useNavigate()

  const onFinish = async (values: any) => {
    try {
      const payload = {
        ...values,
        chainId: Number(values?.chainId),
      }

      const result = await createToken(payload)
      notification.success({ message: `created ${result?.data.id}` })
      navigate('/token-list')
    } catch (error) {
      notification.error({ message: 'Something went wrong' })
    }
  }

  return (
    <>
      <Layout>
        <div
          style={{
            maxWidth: '1200px',
            width: '100%',
            margin: '0 auto',
            padding: '32px 64px',
          }}
        >
          <div>
            <h1 style={{ textAlign: 'center' }}>Create Token</h1>
            <Form
              name="basic"
              labelCol={{ span: 8, md: { span: 8 } }}
              wrapperCol={{ span: 8 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input your name!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Symbol"
                name="symbol"
                rules={[{ required: true, message: 'Please input your symbol!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Symbol icon"
                name="symbolIcon"
                rules={[
                  { required: true, message: 'Please input your symbolIcon!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="CoingeckoId"
                name="coingeckoId"
                rules={[
                  { required: true, message: 'Please input your coingeckoId!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Address"
                name="address"
                rules={[{ required: true, message: 'Please input your address!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Chain ID"
                name="chainId"
                rules={[{ required: true, message: 'Please input your chainId!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Total Supply" name="totalSupply">
                <Input />
              </Form.Item>

              <Form.Item label="Circulating supply" name="circulatingSupply">
                <Input />
              </Form.Item>

              <Form.Item label="Price" name="price">
                <Input />
              </Form.Item>

              <Form.Item label="Remark" name="remark">
                <Input />
              </Form.Item>

              <Form.Item
                wrapperCol={{ offset: 0, span: 24, md: { offset: 8, span: 8 } }}
              >
                <Button type="primary" htmlType="submit" block>
                  Create
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default EditToken
