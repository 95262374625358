import { Button, notification, Popconfirm, Table, Form, Input } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Layout from '../../components/Layout'
type LayoutType = Parameters<typeof Form>[0]['layout']
const GenVesting = () => {
  const [form] = Form.useForm()
  const [formLayout, setFormLayout] = useState<LayoutType>('horizontal')
  const dataSource = [
    {
      key: '1',
      name: 'Mike',
      age: 32,
      address: '10 Downing Street',
    },
    {
      key: '2',
      name: 'John',
      age: 42,
      address: '10 Downing Street',
    },
  ]

  const columns = [
    {
      title: 'Vesting Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Start Block',
      dataIndex: 'startBlock',
      key: 'startBlock',
    },
    {
      title: 'End Block',
      dataIndex: 'endBlock',
      key: 'endBlock',
    },
    {
      title: 'Start Amount',
      dataIndex: 'startAmount',
      key: 'startAmount',
    },
    {
      title: 'End Amount',
      dataIndex: 'endAmount',
      key: 'endAmount',
    },
    {
      title: 'No Ending?',
      dataIndex: 'noEnding',
      key: 'noEnding',
    },
  ]

  return (
    <>
      <Layout>
        <div
          style={{
            maxWidth: '1500px',
            width: '100%',
            margin: '0 auto',
            padding: '32px 64px',
          }}
        >
          <Form
            layout="inline"
            form={form}
            initialValues={{ layout: formLayout }}
            style={{ marginBottom: '16px' }}
          >
            <Form.Item label="Vesting Name" name="name">
              <Input placeholder="vesting name" />
            </Form.Item>
            <Form.Item label="Total Amount" name="amount">
              <Input placeholder="total amount" />
            </Form.Item>
            <Form.Item label="Duration" name="duration">
              <Input type="number" placeholder="total amount" />
            </Form.Item>
            <Button type="primary">Generate</Button>
          </Form>
          <Table dataSource={dataSource} columns={columns} />;
        </div>
      </Layout>
    </>
  )
}

export default GenVesting
