import {
  Button,
  DatePicker,
  Form,
  Input,
  notification,
  Select,
  TimePicker,
} from 'antd'

import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

import Layout from '../../components/Layout'
import { getTokenList } from '../../services/token.service'
import { createVesting } from '../../services/vesting.service'

const CreateVesting = () => {
  const navigate = useNavigate()

  const [tokenList, setTokenList] = useState([])

  useEffect(() => {
    hanleFetchTokenList()
  }, [])

  const onFinish = async (values: any) => {
    try {
      const payload = {
        ...values,
        beginDate: dayjs(
          `${dayjs.unix(values?.beginDate / 1000).format('MM/DD/YYYY')} ${dayjs
            .unix(values?.time / 1000)
            .format('HH:mm:ss')}`,
          'MM/DD/YYYY HH:mm:ss'
        ).valueOf(),
        cliffInMonth: Number(values?.cliffInMonth),
        fullReleaseInMonth: Number(values?.fullReleaseInMonth),
        time: undefined,
        label: 'Manual',
      }

      const result = await createVesting(payload)
      notification.success({ message: `created ${result?.data.id}` })
      navigate('/')
    } catch (error) {
      notification.error({ message: 'Something went wrong' })
    }
  }

  const hanleFetchTokenList = async () => {
    try {
      const { data } = await getTokenList()
      setTokenList(data?.tokens)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Layout>
        <div
          style={{
            maxWidth: '1200px',
            width: '100%',
            margin: '0 auto',
            padding: '32px 64px',
          }}
        >
          <div>
            <h1 style={{ textAlign: 'center' }}>Create Vesting</h1>

            <Form
              name="basic"
              labelCol={{ span: 8, md: { span: 8 } }}
              wrapperCol={{ span: 8 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Token"
                name="tokenId"
                rules={[{ required: true, message: 'Please input your tokenId!' }]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Search token"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).includes(input)
                  }
                >
                  {tokenList?.map((e) => (
                    <Select.Option key={e?.id} value={e?.id}>
                      {e?.name} (chainId {e?.chainId})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Contract address"
                name="contractAddress"
                rules={[
                  { required: true, message: 'Please input your contractAddress!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Begin date"
                name="beginDate"
                rules={[{ required: true, message: 'Please input your beginDate!' }]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item
                label="Time"
                name="time"
                rules={[{ required: true, message: 'Please input your time!' }]}
              >
                <TimePicker style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item
                label="Amount"
                name="amount"
                rules={[{ required: true, message: 'Please input your amount!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Owner"
                name="owner"
                rules={[{ required: true, message: 'Please input your owner!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Cliff in month"
                name="cliffInMonth"
                rules={[
                  { required: true, message: 'Please input your cliffInMonth!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Full release in month"
                name="fullReleaseInMonth"
                rules={[
                  {
                    required: true,
                    message: 'Please input your fullReleaseInMonth!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Protocol"
                name="protocol"
                rules={[{ required: true, message: 'Please input your protocol!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Label" name="label">
                <Input defaultValue="Manual" disabled />
              </Form.Item>

              <Form.Item label="Tx" name="tx">
                <Input />
              </Form.Item>

              <Form.Item label="Next unlock value" name="nextUnlockValue">
                <Input />
              </Form.Item>

              <Form.Item label="Unclaim Amount" name="unClaimAmount">
                <Input />
              </Form.Item>

              <Form.Item label="Wallet type" name="walletType">
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Search wallet type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).includes(input)
                  }
                >
                  <Select.Option value="Wallet">Wallet</Select.Option>
                  <Select.Option value="Smart contract">
                    Smart contract
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item label="Unlock date type" name="unlockDateType">
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Search unlock date type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).includes(input)
                  }
                >
                  <Select.Option value="Exact time">Exact time</Select.Option>
                  <Select.Option value="Approximate time">
                    Approximate time
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item label="Vesting owner" name="vestingOwner">
                <Input />
              </Form.Item>

              <Form.Item label="Remark" name="remark">
                <Input />
              </Form.Item>

              <Form.Item
                wrapperCol={{ offset: 0, span: 24, md: { offset: 8, span: 8 } }}
              >
                <Button type="primary" htmlType="submit" block>
                  Create
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default CreateVesting
