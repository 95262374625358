import { Button, Divider, Form, Input, notification } from 'antd'
import { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import Layout from '../../../components/Layout'
import { updateToken, getTokenById } from '../../../services/token.service'

const EditToken = () => {
  const [form] = Form.useForm()
  const param = useParams()

  const navigate = useNavigate()
  const [token, setToken] = useState<any>(null)

  useEffect(() => {
    handleGetTokenById(param?.id)
  }, [])

  const handleGetTokenById = async (id: string) => {
    try {
      const {
        data: { data },
      } = await getTokenById(id)
      const addresses = Object.keys(data?.addresses).map((value, index) => {
        return {
          address: data?.addresses[value].address,
          chainId: value,
        }
      })
      console.log('data: ', data)
      console.log('addresses: ', addresses)
      form?.setFieldsValue({
        name: data?.name,
        symbol: data?.symbol,
        symbolIcon: data?.symbolIcon,
        description: data?.description,
        coingeckoId: data?.coingeckoId,
        addresses: addresses,
        totalSupply: data?.totalSupply,
        price: data?.price,
        circulatingSupply: data?.circulatingSupply,
        remark: data?.remark,
      })
      setToken(data)
    } catch (error) {
      console.error(error)
    }
  }

  const onFinish = async (values: any) => {
    try {
      const payload = {
        ...values,
        chainId: Number(values?.chainId),
      }

      const result = await updateToken(token?.id, payload)
      notification.success({ message: `updated ${result?.data.id}` })
      navigate('/token-list')
    } catch (error) {
      notification.error({ message: 'Something went wrong' })
    }
  }

  return (
    <>
      <Layout>
        <div
          style={{
            maxWidth: '1200px',
            width: '100%',
            margin: '0 auto',
            padding: '32px 64px',
          }}
        >
          <div>
            <h1 style={{ textAlign: 'center' }}>Update Token</h1>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8, md: { span: 8 } }}
              wrapperCol={{ span: 8 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input your name!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Symbol"
                name="symbol"
                rules={[{ required: true, message: 'Please input your symbol!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Symbol icon"
                name="symbolIcon"
                rules={[
                  { required: true, message: 'Please input your symbolIcon!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="CoingeckoId"
                name="coingeckoId"
                rules={[
                  { required: true, message: 'Please input your coingeckoId!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: 'Please input your description' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Address"
                name="description"
                rules={[
                  { required: true, message: 'Please input your description' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Total Supply" name="totalSupply">
                <Input />
              </Form.Item>

              <Form.Item label="Circulating supply" name="circulatingSupply">
                <Input />
              </Form.Item>

              <Form.Item label="Price" name="price">
                <Input />
              </Form.Item>

              <Form.Item label="Remark" name="remark">
                <Input />
              </Form.Item>
              <Divider>Address</Divider>
              <Form.List name="addresses">
                {(addresses, { add, remove }) => {
                  return (
                    <div>
                      {addresses.map((address, index) => (
                        <div key={address.key}>
                          <Form.Item
                            name={[index, 'address']}
                            label="address"
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name={[index, 'chainId']}
                            label="chainId"
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                      ))}
                      <Form.Item
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          style={{ width: '60%' }}
                        >
                          <PlusOutlined /> Add field
                        </Button>
                      </Form.Item>
                    </div>
                  )
                }}
              </Form.List>

              <Form.Item
                wrapperCol={{ offset: 0, span: 24, md: { offset: 8, span: 8 } }}
              >
                <Button type="primary" htmlType="submit" block>
                  Update
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default EditToken
