import axios, { AxiosRequestConfig } from 'axios'

const fetch = async (
  method: AxiosRequestConfig['method'],
  endpoint: string = '/',
  body: Object = {},
  options: Object = {},
  apiUrl = process.env.REACT_APP_API_URL,
  headers: Object = {}
) => {
  const url = `${apiUrl}/${endpoint}`
  const data = body
  const queryName = method === 'GET' ? 'params' : 'data'

  const api: any = axios.create({
    baseURL: url,
  })

  api.interceptors.request.use((config: any) => {
    return config
  })

  const res = await api.request({
    method,
    url,
    [queryName]: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      Authorization: `Bearer ${sessionStorage.getItem('credential')}`,
      ...headers,
    },
    ...options,
  })

  return Promise.resolve(res)
}

export default fetch
