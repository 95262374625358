import { enableStaticRendering } from 'mobx-react-lite'
import React, { createContext, ReactNode } from 'react'
import { Store } from '../stores'

enableStaticRendering(typeof window === 'undefined')

let store: Store
const StoreContext = createContext<Store | undefined>(undefined)
StoreContext.displayName = 'StoreContext'

function RootStoreProvider({ children }: { children: ReactNode }) {
  const store = initializeStore()

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

function initializeStore(): Store {
  const _store = store ?? new Store()

  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export { RootStoreProvider, StoreContext }
