import { List, notification, Select, Spin, Table, Tabs } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Row, Space } from 'antd'
import Layout from '../../../components/Layout'
import csv from 'csvtojson'
import {
  createTokenLink,
  getTokenById,
  updateTokens,
} from '../../../services/token.service'
import * as XLSX from 'xlsx'
import { usePapaParse } from 'react-papaparse'
import { worksheetConfig, v2Template } from '../../../configs/worksheet.config'
import {
  createVesting,
  generateChartData,
  generatePreviewData,
} from '../../../services/vesting.service'
import dayjs from 'dayjs'
import { convertToDate } from '../../../utils/convert-date'
import { createAllocation } from '../../../services/allocation.service'
import { getCurrentBlockInfo } from '../../../utils/ethereum'
import { AllocationTable } from './AllocationTable'
import { RoleTable } from './RoleTable'
import { ReferencesTable } from './ReferenceTable'
import ConfirmModal from './ConfirmModal'
import PreviewChartDataModal from './PreviewChartDataModal'
import { PreviewChart } from './PreviewChart'
import { groupAllocationColor } from '../../../utils/group'
import { checkEndDate } from '../../../utils/asserts'
import {
  TokenUpdate,
  Allocation,
  Role,
  Vesting,
  Reference,
} from '../../../types/vesting'

enum PreviewTab {
  Vestings = 'vestings',
  Allocations = 'allocations',
  Roles = 'roles',
  References = 'refferences',
}

const { TabPane } = Tabs
const { Option } = Select

const VestingTable = (props) => {
  const { datasource } = props

  const columns = [
    {
      title: 'Begin Date',
      dataIndex: 'beginDate',
      key: 'beginDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Start Block',
      dataIndex: 'startBlock',
      key: 'startBlock',
    },
    {
      title: 'End Block',
      dataIndex: 'endBlock',
      key: 'endBlock',
    },
    {
      title: 'beginAmount',
      dataIndex: 'beginAmount',
      key: 'beginAmount',
    },
    {
      title: 'endAmount',
      dataIndex: 'endAmount',
      key: 'endAmount',
    },
  ]

  return <Table dataSource={datasource} columns={columns} />
}

interface VestingWPData {
  [key: string]: Vesting
}

interface VestingTrackedData {
  [key: string]: Vesting
}
enum SelectData {
  Wp,
  Tracked,
}

const UploadVestingV2 = () => {
  const [loading, setIsLoading] = useState<boolean>(false)
  const [coingekcoId, setCoingekcoId] = useState<string>('')
  const [tokenUpdate, setTokenUpdate] = useState<TokenUpdate>({})

  const [vestingsWp, setVestingsWp] = useState<any>()
  const [vestingsTracked, setVestingsTracked] = useState<any>()
  const [selectData, setSelectData] = useState<SelectData>(SelectData.Wp)

  const [allocationsWp, setAllocationsWp] = useState<Allocation[]>([])
  const [allocationsTracked, setAllocationsTracked] = useState<Allocation[]>([])
  const [roles, setRoles] = useState<Role[]>([])
  const [references, setReferences] = useState<Role[]>([])

  const [chartData, setChartData] = useState<any>(null)

  const changeHandler = async (event) => {
    const [file] = event.target.files

    const reader = new FileReader()

    reader.onload = async (evt) => {
      console.log('reading...')
      const bstr = evt.target.result

      const workbook = XLSX.read(bstr, { type: 'binary' })
      let vestingWpData: VestingWPData = {}
      let vestingTrackedData: VestingTrackedData = {}
      let updateData: TokenUpdate = {
        id: '',
        description: '',
        emissionType: '',
        predictYear: '',
        predictSupply: '',
      }
      let tokenInfo

      // So if you want to read multiple sheets you can use the list of sheet names (workbook.SheetNames) and the sheets object (workbook.Sheets). I would use a for-loop to go through each sheet name in the SheetNames array, then access the Sheets object by passing in the sheet name to get the sheet.
      // for (let i = 0; i < workbook.SheetNames.length; ++i) {
      for await (const sheetName of workbook.SheetNames) {
        const sheet = workbook.Sheets[sheetName]
        const data = XLSX.utils.sheet_to_csv(sheet)

        const results = await csv({
          noheader: true,
          output: 'csv',
        }).fromString(data)

        try {
          // Working with general info
          if (sheetName === 'Project') {
            console.log('-----------------Project Sheet-----------------')

            const coingekcoId = results[v2Template.general.coingeckoId][1]

            setCoingekcoId(coingekcoId)

            const { data } = await getTokenById(coingekcoId)

            const token = data.data
            console.log('token: ', token)
            tokenInfo = { ...token, id: token.id }
            updateData.id = token.id

            for (const [index, value] of results.entries()) {
              switch (index) {
                case v2Template.general.description:
                  updateData.description = value[1]
                  break
                case v2Template.general.emissionType:
                  updateData.emissionType = value[1]
                  break
                case v2Template.general.predictSupply:
                  updateData.predictSupply = value[1]
                  break
                case v2Template.general.predictYear:
                  updateData.predictYear = value[1]
                  break
                case v2Template.general.supplyChartDateForWp:
                  updateData.supplyChartDateForWp = value[1]
                    ? dayjs(convertToDate(value[1])).format('YYYY-MM-DD HH:mm:ss')
                    : null
                  break
                case v2Template.general.supplyChartDateForOnChain:
                  updateData.supplyChartDateForOnChain = value[1]
                    ? dayjs(convertToDate(value[1])).format('YYYY-MM-DD HH:mm:ss')
                    : null
                  break
                default:
                  break
              }

              // vesting wp
              if (
                index >= v2Template.vestingWp.start &&
                index < v2Template.vestingTracked.start - 1 &&
                value[0] &&
                value[1]
              ) {
                const vestingIndex = v2Template.vestingWp.cols
                const constructData = {
                  group: value[vestingIndex.allocation],
                  tokenId: token.id,
                  chainId: value[vestingIndex.chainId],
                  address: value[vestingIndex.address],
                  unclaimAmount: '0',
                  amount: value[vestingIndex.amount].replace(/\,/g, ''),
                  label: value[vestingIndex.label],
                  registerType: value[vestingIndex.registerType],
                  supplyType: value[vestingIndex.supplyType],
                  dataSource: value[vestingIndex.reference],
                  walletType: value[vestingIndex.walletType],
                  vestingType: value[vestingIndex.vestingType],
                  tx: value[vestingIndex.tx],
                  beginDate: '',
                  endDate: '',
                  startBlock: 0,
                  endBlock: 0,
                  cards: [],
                  claims: [],
                  roles: [],
                }
                vestingWpData[value[vestingIndex.label]] = constructData
              }
              // vesting tracked
              if (index >= v2Template.vestingTracked.start && value[0] && value[1]) {
                const vestingIndex = v2Template.vestingTracked.cols
                const constructData = {
                  group: value[vestingIndex.allocation],
                  tokenId: token.id,
                  chainId: value[vestingIndex.chainId],
                  address: value[vestingIndex.address],
                  unclaimAmount: '0',
                  amount: value[vestingIndex.amount].replace(/\,/g, ''),
                  label: value[vestingIndex.label],
                  registerType: value[vestingIndex.registerType],
                  supplyType: value[vestingIndex.supplyType],
                  dataSource: value[vestingIndex.reference],
                  walletType: value[vestingIndex.walletType],
                  vestingType: value[vestingIndex.vestingType],
                  tx: value[vestingIndex.tx],
                  beginDate: '',
                  endDate: '',
                  startBlock: 0,
                  endBlock: 0,
                  cards: [],
                  claims: [],
                  roles: [],
                }
                vestingTrackedData[value[vestingIndex.label]] = constructData
              }
            }
            setTokenUpdate(updateData)
          }
          // Working with wp vesting info
          if (sheetName === 'Vesting_WP') {
            for (const [index, value] of results.entries()) {
              if (index >= v2Template.vestingCardWP.start) {
                const vestIndex = v2Template.vestingCardWP.cols
                if (value[0]) {
                  checkEndDate(
                    value[vestIndex.startDate],
                    value[vestIndex.endDate],
                    sheetName,
                    index
                  )
                  const constructData = {
                    beginAmount: value[vestIndex.startAmount].replace(/\,/g, ''),
                    endAmount: value[vestIndex.endAmount].replace(/\,/g, ''),
                    beginDate: value[vestIndex.startDate]
                      ? dayjs(convertToDate(value[vestIndex.startDate])).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                      : '',
                    endDate: value[vestIndex.endDate]
                      ? value[vestIndex.endDate] != '-'
                        ? dayjs(convertToDate(value[vestIndex.endDate])).format(
                            'YYYY-MM-DD HH:mm:ss'
                          )
                        : ''
                      : '',
                    startBlock: +value[vestIndex.startBlock].replace(/\,/g, '')
                      ? +value[vestIndex.startBlock].replace(/\,/g, '')
                      : 0,
                    endBlock: +value[vestIndex.endBlock].replace(/\,/g, '')
                      ? +value[vestIndex.endBlock].replace(/\,/g, '')
                      : 0,
                  }
                  if (vestingWpData[value[vestIndex.vesting]]) {
                    vestingWpData[value[vestIndex.vesting]].cards = [
                      ...vestingWpData[value[vestIndex.vesting]].cards,
                      constructData,
                    ]
                  }
                }
              }
            }
            // ReconstructData
            for (const vestingItem of Object.values(vestingWpData)) {
              vestingWpData[vestingItem.label] = {
                ...vestingWpData[vestingItem.label],
                beginDate:
                  vestingWpData[vestingItem.label].cards[0]?.beginDate ?? '',
                endDate:
                  vestingWpData[vestingItem.label].cards[
                    vestingWpData[vestingItem.label].cards.length - 1
                  ]?.endDate ?? '',
                startBlock:
                  +vestingWpData[vestingItem.label].cards[0]?.startBlock ?? 0,
                endBlock:
                  +vestingWpData[vestingItem.label].cards[
                    vestingWpData[vestingItem.label].cards.length - 1
                  ]?.endBlock ?? 0,
              }
            }
            console.log('vesting wp: ', vestingWpData)
            setVestingsWp(vestingWpData)
          }

          // Working with tracked vesting info
          if (sheetName === 'Vesting_Tracked') {
            console.log('vesting')

            for (const [index, value] of results.entries()) {
              if (index >= v2Template.vestingCardTracked.start) {
                const vestIndex = v2Template.vestingCardTracked.cols
                if (value[0]) {
                  checkEndDate(
                    value[vestIndex.startDate],
                    value[vestIndex.endDate],
                    sheetName,
                    index
                  )
                  const constructData = {
                    beginAmount: value[vestIndex.startAmount].replace(/\,/g, ''),
                    endAmount: value[vestIndex.endAmount].replace(/\,/g, ''),
                    beginDate: value[vestIndex.startDate]
                      ? dayjs(convertToDate(value[vestIndex.startDate])).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                      : '',
                    endDate: value[vestIndex.endDate]
                      ? dayjs(convertToDate(value[vestIndex.endDate])).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                      : '',
                    startBlock: +value[vestIndex.startBlock].replace(/\,/g, '')
                      ? +value[vestIndex.startBlock].replace(/\,/g, '')
                      : 0,
                    endBlock: +value[vestIndex.endBlock].replace(/\,/g, '')
                      ? +value[vestIndex.endBlock].replace(/\,/g, '')
                      : 0,
                  }
                  if (vestingTrackedData[value[vestIndex.vesting]]) {
                    vestingTrackedData[value[vestIndex.vesting]].cards = [
                      ...vestingTrackedData[value[vestIndex.vesting]].cards,
                      constructData,
                    ]
                  }
                }
              }
            }
            // ReconstructData
            for (const vestingItem of Object.values(vestingTrackedData)) {
              vestingTrackedData[vestingItem.label] = {
                ...vestingTrackedData[vestingItem.label],
                beginDate:
                  vestingTrackedData[vestingItem.label].cards[0]?.beginDate ?? '',
                endDate:
                  vestingTrackedData[vestingItem.label].cards[
                    vestingTrackedData[vestingItem.label].cards.length - 1
                  ]?.endDate ?? '',
                startBlock:
                  +vestingTrackedData[vestingItem.label].cards[0]?.startBlock ?? 0,
                endBlock:
                  +vestingTrackedData[vestingItem.label].cards[
                    vestingTrackedData[vestingItem.label].cards.length - 1
                  ]?.endBlock ?? 0,
              }
            }
            console.log('vesting tracked: ', vestingTrackedData)
            setVestingsTracked(vestingTrackedData)
          }

          // // Working with allocation info
          if (sheetName === 'Allocation_WP') {
            console.log('token: ', tokenInfo)

            let allocationWpData = []
            for (const [index, value] of results.entries()) {
              const allocationIndex = v2Template.allocationWp.cols

              if (index >= v2Template.allocationWp.start && value[0].length !== 0) {
                const constructData: Allocation = {
                  tokenId: tokenInfo.id,
                  groupName: value[allocationIndex.title],
                  groupNameCheck: true,
                  amount: value[allocationIndex.amount].replace(/\,/g, ''),
                  status: true,
                  chartPriority: +value[allocationIndex.chartIndex].replace(
                    /\,/g,
                    ''
                  ),
                  groupPriority: +value[allocationIndex.groupIndex].replace(
                    /\,/g,
                    ''
                  ),
                  color: value[allocationIndex.color],
                  percentage: value[allocationIndex?.percentage],
                  description: value[allocationIndex?.description],
                }
                allocationWpData.push(constructData)
              }
            }

            setAllocationsWp(allocationWpData)
          }

          // // Working with allocation info
          if (sheetName === 'Allocation_Tracked') {
            let allocationTrackedData = []
            for (const [index, value] of results.entries()) {
              const allocationIndex = v2Template.allocationTracked.cols

              if (
                index >= v2Template.allocationTracked.start &&
                value[0].length !== 0
              ) {
                const constructData: Allocation = {
                  tokenId: tokenInfo.id,
                  groupName: value[allocationIndex.title],
                  groupNameCheck: true,
                  amount: value[allocationIndex.amount].replace(/\,/g, ''),
                  status: true,
                  chartPriority: +value[allocationIndex.chartIndex].replace(
                    /\,/g,
                    ''
                  ),
                  groupPriority: +value[allocationIndex.groupIndex].replace(
                    /\,/g,
                    ''
                  ),
                  color: value[allocationIndex.color],
                  percentage: value[allocationIndex?.percentage],
                  description: value[allocationIndex?.description],
                }

                allocationTrackedData.push(constructData)
              }
            }

            setAllocationsTracked(allocationTrackedData)
          }

          // Working with Roles info
          if (sheetName === 'Roles') {
            let rolesData = []

            results.forEach((role, index) => {
              const roleIndex = v2Template.roles.cols
              if (index >= v2Template.roles.start && role[0].length !== 0) {
                const constructData: Role = {
                  name: role[roleIndex.label],
                  address: role[roleIndex.address],
                  role: role[roleIndex.role],
                  group: role[roleIndex.vesting],
                }
                rolesData.push(constructData)
              }
            })
            setRoles(rolesData)
          }

          // Working with Roles info
          if (sheetName === 'References') {
            let referencesData = []

            results.map((ref, index) => {
              const refIndex = v2Template.reference.cols

              if (index >= v2Template.reference.start && ref[0].length !== 0) {
                const constructData: Reference = {
                  label: ref[refIndex.label],
                  url: ref[refIndex.link],
                  type: ref[refIndex.type],
                }
                referencesData.push(constructData)
              }
            })
            setReferences(referencesData)
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
    reader.readAsBinaryString(file)
  }

  const onConfirmVesting = async () => {
    try {
      setIsLoading(true)
      let vestings
      let allocations
      if (selectData == SelectData.Wp) {
        vestings = vestingsWp
        allocations = allocationsWp
      } else {
        vestings = vestingsTracked
        allocations = allocationsTracked
      }
      if (vestings) {
        const vestingsArray = Object.values(vestings).map(
          (vesting: Vesting, index) => {
            return {
              ...vesting,
              roles: roles.filter((role) => role.group === vesting.label),
            }
          }
        )
        const onchain = selectData == SelectData.Tracked
        const currentBlockInfo = await getCurrentBlockInfo()
        const vestingsPayload = {
          data: vestingsArray,
          onchain,
          currentBlockInfo: currentBlockInfo,
        }
        const allocationsPayload = { data: allocations, onchain }
        const updateTokensPayload = { data: [tokenUpdate] }
        const referencesPayload = {
          data: references.map((ref, index) => ({
            ...ref,
            tokenId: tokenUpdate.id,
          })),
        }

        console.log('updateTokens payload', updateTokensPayload)
        console.log('allocation  payload', allocationsPayload)
        console.log('vesting payload', vestingsPayload)
        console.log('references payload', referencesPayload)

        const resultUpdateToken = await updateTokens(updateTokensPayload)
        const resultVesting = await createVesting(vestingsPayload)
        const resultAllocation = await createAllocation(allocationsPayload)
        const resultReference = await createTokenLink(referencesPayload)

        generateChartData(coingekcoId, onchain)
        console.log('generating chart data')

        console.log('result vesting: ', resultVesting)
        console.log('result update: ', resultUpdateToken)
        console.log('result allocation: ', resultAllocation)

        console.log('result reference: ', resultReference)
        setIsLoading(false)
        notification.success({ message: `Complete!` })
      }

      //   navigate('/')
    } catch (error) {
      console.log('error: ', error)
      setIsLoading(false)
      notification.error({ message: 'Something went wrong' })
    }
  }

  const onConfirmPreview = async (period: string) => {
    try {
      let vestings: any
      if (selectData === SelectData.Wp) {
        vestings = vestingsWp
      } else {
        vestings = vestingsTracked
      }

      if (vestings) {
        setIsLoading(true)
        const vestingsArray = Object.values(vestings).map(
          (vesting: Vesting, index) => {
            return {
              ...vesting,
              roles: roles.filter((role) => role?.group === vesting?.group),
            }
          }
        )
        const currentBlockInfo = await getCurrentBlockInfo()
        const vestingsPayload = {
          data: vestingsArray,
          currentBlockInfo: currentBlockInfo,
        }
        const {
          data: { chartData },
        } = await generatePreviewData({
          ...vestingsPayload,
          step: period,
        })
        setChartData(chartData)
        setIsLoading(false)
      }
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const chart = useMemo(() => chartData, [chartData])

  const VestingPreview = useMemo(() => {
    let vestings
    let allocations
    if (selectData == SelectData.Wp) {
      vestings = vestingsWp
      allocations = allocationsWp
    } else {
      vestings = vestingsTracked
      allocations = allocationsTracked
    }
    if (vestings) {
      return (
        <>
          <div
            style={{
              display: 'flex',
              padding: '20px',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <h2>Preview Detail</h2>
              <Select
                defaultValue={SelectData.Wp}
                style={{ marginLeft: '10px' }}
                onChange={(value: SelectData) => {
                  setSelectData(value)
                }}
              >
                <Option value={SelectData.Wp}>WP</Option>
                <Option value={SelectData.Tracked}>Tracked</Option>
              </Select>
            </div>

            <div>
              <span>
                <PreviewChartDataModal
                  disabledButton={!vestings || allocations.length === 0}
                  onConfirm={onConfirmPreview}
                  loading={loading}
                />
              </span>
              <span style={{ marginLeft: '8px' }}>
                <ConfirmModal
                  disabledButton={!vestings || allocations.length === 0}
                  onConfirm={onConfirmVesting}
                  loading={loading}
                />
              </span>
            </div>
            {/* <Button onClick={onConfirmVesting}>Confirm Create Vesting</Button> */}
          </div>

          <Tabs style={{ padding: '0 20px' }} defaultActiveKey={PreviewTab.Vestings}>
            <TabPane tab="Vestings" key={PreviewTab.Vestings}>
              <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                {Object.values(vestings).map((vesting: Vesting, index) => {
                  const vestingCards = vesting.cards.map((detail, index) => {
                    return {
                      key: index,
                      beginAmount: detail.beginAmount ? detail.beginAmount : '-',
                      endAmount: detail.endAmount ? detail.endAmount : '-',
                      beginDate: detail.beginDate ? detail.beginDate : '-',
                      endDate: detail.endDate ? detail.endDate : '-',
                      startBlock: detail.startBlock ? detail.startBlock : '-',
                      endBlock: detail.endBlock ? detail.endBlock : '-',
                    }
                  })

                  return (
                    <Card
                      key={vesting.label}
                      headStyle={{ background: '#1890ff', color: 'white' }}
                      bodyStyle={{ background: '#f0f0f0' }}
                      title={`${vesting.label}`}
                      bordered={false}
                    >
                      <h4>General Information</h4>
                      <Row>
                        {Object.keys(vesting).map((key, index) => {
                          if (!Array.isArray(vesting[key])) {
                            return (
                              <Col span={8}>
                                <List.Item>{`${key}: ${
                                  vesting[key] ? vesting[key] : '-'
                                }`}</List.Item>
                              </Col>
                            )
                          }
                        })}
                      </Row>
                      <VestingTable datasource={vestingCards} />
                    </Card>
                  )
                })}
              </Space>
            </TabPane>
            <TabPane tab="Allocations" key={PreviewTab.Allocations}>
              <AllocationTable datasource={allocations} />
            </TabPane>
            <TabPane tab="Roles" key={PreviewTab.Roles}>
              <RoleTable datasource={roles} />
            </TabPane>
            <TabPane tab="References" key={PreviewTab.References}>
              <ReferencesTable datasource={references} />
            </TabPane>
          </Tabs>
        </>
      )
    }
  }, [
    vestingsWp,
    vestingsTracked,
    allocationsWp,
    allocationsTracked,
    selectData,
    loading,
    roles,
    references,
  ])

  return (
    <>
      <Layout>
        <input
          type="file"
          name="file"
          onChange={changeHandler}
          style={{ display: 'block', margin: '10px auto' }}
        />
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spin />
          </div>
        )}
        {(vestingsWp || vestingsTracked) && !loading && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '768px', height: '400px' }}>
              <PreviewChart
                allocations={allocationsWp}
                data={chart ? chart : []}
                colors={
                  allocationsWp && chart
                    ? groupAllocationColor(allocationsWp, chart[0])
                    : []
                }
              />
            </div>
          </div>
        )}
        {VestingPreview}
      </Layout>
    </>
  )
}

export default UploadVestingV2
