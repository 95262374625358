import fetch from '../utils/fetch'

export const getUtilityByTokenId = (tokenId: string) => {
  let method = 'GET'
  let path = `token/utility/${tokenId}`
  return fetch(method, path)
}

export const createUtility = (payload: {
  tokenId: string
  name: string
  description: string
  url: string
  icon: string
  disclaimer?: string
}) => {
  let method = 'POST'
  let path = `token/utility`
  return fetch(method, path, payload)
}

export const updateUtility = (
  utilityId: string,
  payload: {
    tokenId: string
    name: string
    description: string
    url: string
    icon: string
    disclaimer?: string
  }
) => {
  let method = 'PATCH'
  let path = `token/utility/${utilityId}`
  return fetch(method, path, payload)
}

export const deleteUtility = (tokenId: string) => {
  let method = 'DELETE'
  let path = `token/utility/${tokenId}`
  return fetch(method, path)
}
