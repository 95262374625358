import { Table } from 'antd'

export const ReferencesTable = (props) => {
  const { datasource } = props

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
  ]

  return <Table dataSource={datasource} columns={columns} />
}
