import { Navigate } from 'react-router-dom'

interface Props {
  component: React.ComponentType
}

const PrivateRoute = ({ component: RouteComponent }: Props) => {
  const credential = sessionStorage.getItem('credential')

  if (!credential) {
    return <Navigate to="/login" replace />
  }

  return <RouteComponent />
}

export default PrivateRoute
