import { Button, Modal, Spin } from 'antd'
import React, { useState } from 'react'

type Props = {
  onConfirm: any
  loading: boolean
  disabledButton: boolean
}

export const ConfirmModal = (props: Props) => {
  const { onConfirm, loading, disabledButton } = props

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    onConfirm()
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button disabled={disabledButton} type="primary" onClick={showModal}>
        Confirm Create
      </Button>
      <Modal
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: loading }}
      >
        {loading ? <Spin /> : <div>Approve ?</div>}
      </Modal>
    </>
  )
}

export default ConfirmModal
