import {
  Button,
  Checkbox,
  Col,
  Drawer,
  DrawerProps,
  Form,
  Input,
  notification,
  Row,
  Space,
  Tag,
} from 'antd'
import { useCallback, useEffect, useState } from 'react'
import {
  getTokenListByCategory,
  updateCategoryTokens,
} from '../../services/category.service'
import { getTokenList } from '../../services/token.service'

interface CategoryTokensDrawerProps extends DrawerProps {
  values: Record<string, string | number>
}

const CategoryTokensDrawer = (props: CategoryTokensDrawerProps) => {
  const { visible, onClose, values, ...rest } = props

  const [tokens, setTokens] = useState([])

  const [form] = Form.useForm()

  const handleGetTokenList = useCallback(async () => {
    try {
      const { data } = await getTokenList()

      setTokens(data.data)
    } catch (error) {
      console.error(error)
    }
  }, [])

  const handleSubmit = useCallback(async () => {
    try {
      const { tokens } = form.getFieldsValue()
      const payload = { data: tokens }

      await updateCategoryTokens(values.id?.toString(), payload)
      notification.success({ message: `Saved` })

      onClose(null)
    } catch (error) {
      notification.error({ message: 'Something went wrong' })
    }
  }, [form, onClose, values?.id])

  useEffect(() => {
    const getTokensCategory = async () => {
      if (values.id) {
        const { data } = await getTokenListByCategory(values.id?.toString())
        form.setFieldsValue({
          ...values,
          tokens: data.data.map(({ tokenId }) => {
            return tokenId
          }),
        })
      }
    }
    getTokensCategory()
  }, [form, values])

  useEffect(() => {
    handleGetTokenList()
  }, [handleGetTokenList, values])

  return (
    <Drawer
      title="Add / Remove tokens to the category"
      width={720}
      onClose={onClose}
      visible={visible}
      extra={
        <Space>
          <Button onClick={onClose as () => void}>Cancel</Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </Space>
      }
      {...rest}
    >
      <Form layout="vertical" form={form} initialValues={values}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="name" label="Name">
              <Input disabled placeholder="Please enter category name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="priority" label="Priority">
              <Input disabled placeholder="Please enter category name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="tokens" label="Tokens">
              <Checkbox.Group>
                <Row gutter={16}>
                  {tokens?.filter(t => t !== null)?.map((token) => {
                    return (
                      <>
                        <Col span={12}>
                          <Checkbox value={token?.id} style={{ lineHeight: '32px' }}>
                            {token?.name || token?.tokenId} ({token?.platform})
                          </Checkbox>
                        </Col>
                        <Col span={12}>
                          {token?.categories.map((category) => (
                            <Tag>{category?.tokenCategory?.name}</Tag>
                          ))}
                        </Col>
                      </>
                    )
                  })}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  )
}

export default CategoryTokensDrawer
