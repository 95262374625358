import { Table } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Layout from '../../components/Layout'
import { getTokenList } from '../../services/token.service'

const UtilityTokenList = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    handleGetUtilityTokenList()
  }, [])

  const handleGetUtilityTokenList = async () => {
    try {
      const { data } = await getTokenList()

      setData(data.data)
    } catch (error) {
      console.error(error)
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <span>{record?.name}</span>,
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      render: (_, record) => <span>{record?.symbol}</span>,
    },
    {
      title: 'Symbol icon',
      dataIndex: 'symbolIcon',
      key: 'symbolIcon',
      render: (_, record) => (
        <img
          src={record?.symbolIcon}
          style={{ width: '32px', height: '32px' }}
          alt={record?.symbol}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'view',
      key: 'view',
      render: (_, record) => (
        <Link to={`/utility/${record?.coingeckoId}/list`}>View</Link>
      ),
    },
  ]

  return (
    <>
      <Layout>
        <div
          style={{
            width: '100%',
            margin: '0 auto',
            padding: '32px 64px',
          }}
        >
          <div style={{ margin: '32px 0' }}>
            <Table
              columns={columns}
              dataSource={data}
              rowKey="id"
              pagination={{ pageSize: 20 }}
            />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default UtilityTokenList
