import { useMemo } from 'react'
import numeral from 'numeral'
import dayjs from 'dayjs'

import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'

type Props = {
  allocations: any[]
  data: any[]
  colors: {
    [groupName: string]: string
  }
  onColor?: (data) => void
}

export const PreviewChart = ({ allocations, data, colors }: Props) => {
  const colorAndRank = useMemo(() => {
    const data = {}
    allocations.forEach((allocation) => {
      const allocationName = allocation?.groupName
        ?.toLocaleLowerCase()
        ?.replaceAll(' ', '_')
      data[allocationName] = allocation
    })
    return data
  }, [allocations])

  const keys = useMemo(
    () =>
      data && data[0]
        ? Object.keys(data[0])
            .filter((item) => item !== 'name')
            .sort((a, b) => {
              const fieldA = a?.toLocaleLowerCase()?.replaceAll(' ', '_')
              const fieldB = b?.toLocaleLowerCase()?.replaceAll(' ', '_')
              return (
                colorAndRank[fieldB]?.chartPriority -
                colorAndRank[fieldA]?.chartPriority
              )
            })
        : [],
    [colorAndRank, data]
  )
  // const keys = useMemo(
  //   () =>
  //     data && data[0] ? Object.keys(data[0]).filter((item) => item !== 'name') : [],
  //   [data]
  // )

  const renderTooltip = (value) => {
    if (!value?.label) return

    if (value?.active) {
      return (
        <div style={{ backgroundColor: '#ffffff', padding: '16px' }}>
          <div style={{ fontWeight: 'bold' }}>{value?.label}</div>
          {value?.payload?.reverse()?.map((item, idx: number) => (
            <div>
              <div
                style={{ backgroundColor: item?.color, width: '8px', height: '8px' }}
              ></div>
              <div>{item.value}</div>
            </div>
          ))}
        </div>
      )
    }
    return <></>
  }

  return (
    <ResponsiveContainer width={'100%'}>
      <AreaChart
        data={data}
        margin={{
          top: 30,
          right: 20,
          left: 20,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={renderTooltip} />
        <XAxis
          dataKey="name"
          axisLine={false}
          label={{
            value: 'Date (Month/Year)',
            position: 'middle',
            fill: '#eeeeee',
            dy: 20,
          }}
        />
        <YAxis
          tickFormatter={(v) => `${numeral(v).format('0,0a')}`}
          axisLine={false}
          label={{
            value: `Amount`,
            position: 'insideLeft',
            fill: '#eeeeee',
            angle: -90,
            dy: 30,
          }}
        />
        <ReferenceLine
          x={dayjs().format('YYYY-MM-DD')}
          position="end"
          stroke="green"
          label={{
            value: 'Today',
            position: 'top',
            fill: '#eeeeee',
          }}
        />
        {keys.map((item, idx: number) => {
          const field = item?.toLocaleLowerCase()?.replaceAll(' ', '_')
          return (
            <Area
              key={`chart-${idx}`}
              type="stepAfter"
              dataKey={item}
              stackId="1"
              stroke={colorAndRank[field]?.color}
              fill={colorAndRank[field]?.color}
            />
          )
        })}
      </AreaChart>
    </ResponsiveContainer>
  )
}
