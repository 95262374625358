export const group = (arr: any[], key: string) => {
  const result = []
  arr.forEach((item) => {
    const idx = result.findIndex((v) => v[key] === item[key])
    if (idx === -1) {
      result.push({ [key]: item[key], group: [item] })
    } else {
      result[idx].group.push(item)
    }
  })
  return result
}

const defaultColor = [
  'rgba(0, 40, 255, 1)',
  'rgba(0, 217, 247, 1)',
  'rgba(0, 230, 147, 1)',
  'rgba(99, 12, 241, 1)',
  'rgba(164, 37, 242, 1)',
  'rgba(255, 153, 251, 1)',
  'rgba(254, 127, 127, 1)',
  'rgba(255, 192, 98, 1)',
  'rgba(170, 170, 170, 1)',
]

export const groupAllocationColor = (
  allocations,
  draftDataChart: { [key: string]: string }
) => {
  const data = {}

  if (allocations.length > 0) {
    for (const [i, v] of allocations.entries()) {
      const name = v.groupName
      data[name] = v.color || defaultColor[i]
    }
    return data
  } else {
    for (const [i, value] of Object.keys(draftDataChart).entries()) {
      if (value !== 'name') {
        data[value] = defaultColor[i]
      }
    }
    return data
  }
}
