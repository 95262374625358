import { Button, notification, Popconfirm, Table } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import Layout from '../../../components/Layout'
import {
  deleteUtility,
  getUtilityByTokenId,
} from '../../../services/utility.service'
import UtilityCreateModal from './UtilityCreateModal'
import UtilityEditModal from './UtilityEditModal'

const UtilityList = () => {
  const { tokenId } = useParams()

  const [data, setData] = useState([])
  const [isShowCreate, setIsShowCreate] = useState<boolean>(false)
  const [isShowEdit, setIsShowEdit] = useState<string>('')

  const handleGetUtilityTokenList = useCallback(async () => {
    try {
      const { data } = await getUtilityByTokenId(tokenId)

      setData(data.data)
    } catch (error) {
      console.error(error)
    }
  }, [tokenId])

  const handleDeleteUtility = useCallback(
    async (tokenId: string) => {
      try {
        await deleteUtility(tokenId)
        handleGetUtilityTokenList()
        notification.success({ message: `delete ${tokenId}` })
      } catch (error) {
        console.error(error)
      }
    },
    [handleGetUtilityTokenList]
  )

  const handleToggleCreateUtility = () => {
    setIsShowCreate((prev) => !prev)
  }

  const handleCreateUtility = () => {
    handleToggleCreateUtility()
    handleGetUtilityTokenList()
  }

  const handleToggleEditUtility = (utility?: string) => {
    if (typeof utility === 'string') {
      setIsShowEdit(utility)
    } else {
      setIsShowEdit('')
    }
  }

  const handleEditUtility = () => {
    handleToggleEditUtility()
    handleGetUtilityTokenList()
  }

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => <span>{record?.name}</span>,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (_, record) => <span>{record?.description}</span>,
      },
      {
        title: 'Url',
        dataIndex: 'url',
        key: 'url',
        render: (_, record) => <span>{record?.url}</span>,
      },
      {
        title: 'Icon',
        dataIndex: 'icon',
        key: 'icon',
        render: (_, record) => (
          <img
            src={record?.icon}
            style={{ width: '32px', height: '32px' }}
            alt={record?.name}
          />
        ),
      },
      {
        title: 'Disclaimer',
        dataIndex: 'disclaimer',
        key: 'disclaimer',
        render: (_, record) => <span>{record?.disclaimer}</span>,
      },
      {
        title: '',
        dataIndex: 'edit',
        key: 'edit',
        render: (_, record) => (
          <span
            style={{
              marginRight: '8px',
              color: 'blue',
              cursor: 'pointer',
            }}
            onClick={() => handleToggleEditUtility(record?.id)}
          >
            Edit
          </span>
        ),
      },
      {
        title: '',
        dataIndex: 'delete',
        key: 'delete',
        render: (_, record) => {
          return (
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => handleDeleteUtility(record?.id)}
              okText="Yes"
              cancelText="No"
            >
              <span
                style={{
                  marginRight: '8px',
                  color: 'red',
                  cursor: 'pointer',
                }}
              >
                Delete
              </span>
            </Popconfirm>
          )
        },
      },
    ],
    [handleDeleteUtility]
  )

  useEffect(() => {
    handleGetUtilityTokenList()
  }, [handleGetUtilityTokenList])

  return (
    <>
      <Layout>
        <div
          style={{
            width: '100%',
            margin: '0 auto',
            padding: '32px 64px',
          }}
        >
          {data.length < 3 && (
            <div style={{ display: 'flex' }}>
              <Button type="primary" onClick={handleToggleCreateUtility}>
                Create Utility
              </Button>
            </div>
          )}

          <div style={{ margin: '32px 0' }}>
            <Table
              columns={columns}
              dataSource={data}
              rowKey="id"
              pagination={{ pageSize: 20 }}
            />
          </div>
        </div>
      </Layout>
      {isShowCreate && (
        <UtilityCreateModal
          title="Create Utility"
          visible={isShowCreate}
          onOk={handleCreateUtility}
          onCancel={handleToggleCreateUtility}
          maskClosable={false}
        />
      )}
      {!!isShowEdit && (
        <UtilityEditModal
          utilityId={isShowEdit}
          title="Edit Utility"
          visible={!!isShowEdit}
          onOk={handleEditUtility}
          onCancel={handleToggleEditUtility}
          maskClosable={false}
        />
      )}
    </>
  )
}

export default UtilityList
