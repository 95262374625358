import {
  Button,
  Col,
  Drawer,
  DrawerProps,
  Form,
  Input,
  notification,
  Row,
  Space,
} from 'antd'
import { useCallback, useEffect } from 'react'
import { updateCategory } from '../../services/category.service'

interface CategoryEditDrawerProps extends DrawerProps {
  values: Record<string, string | number>
}

const CategoryEditDrawer = (props: CategoryEditDrawerProps) => {
  const { visible, onClose, values, ...rest } = props

  const [form] = Form.useForm()

  const handleSubmit = useCallback(async () => {
    try {
      const { name } = form.getFieldsValue()
      const payload = { name }

      await updateCategory(values.id?.toString(), payload)
      notification.success({ message: `Saved` })

      onClose(null)
    } catch (error) {
      notification.error({ message: 'Something went wrong' })
    }
  }, [form, onClose, values?.id])

  useEffect(() => {
    form.setFieldsValue(values)
  }, [form, values])

  return (
    <Drawer
      title="Edit category"
      width={720}
      onClose={onClose}
      visible={visible}
      extra={
        <Space>
          <Button onClick={onClose as () => void}>Cancel</Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </Space>
      }
      {...rest}
    >
      <Form layout="vertical" form={form} initialValues={values}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please enter category name' }]}
            >
              <Input placeholder="Please enter category name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="priority" label="Priority">
              <Input disabled placeholder="Please enter category name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  )
}

export default CategoryEditDrawer
