import {
  Route,
  Routes,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom'

import { createBrowserHistory } from 'history'
import Login from './views/Login'
import Home from './views/Home'
import PrivateRoute from './components/PrivateRoute'
import CreateVesting from './views/CreateVesting'
import EditVesting from './views/EditVesting'
import TokenList from './views/token/TokenList'
import CreateToken from './views/token/CreateToken'
import EditToken from './views/token/EditToken'
import SubscriptionList from './views/SubscriptionList'
import UploadOldVesting from './views/upload/UploadOldVesting'
import UploadVestingV1 from './views/upload/UploadVestingV1'
import UploadVestingV2 from './views/upload/UploadVestingV2'
import ChainList from './views/chain/ChainList'
import GenVesting from './views/tool/GenVesting'
import UtilityTokenList from './views/utility/UtilityTokenList'
import UtilityList from './views/utility/UtilityList'
import CategoryList from './views/category/CategoryList'

import { AuthProvider } from './providers/AuthProvider'

const history = createBrowserHistory({ window })

const App = () => {
  return (
    <HistoryRouter history={history}>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute component={Home} />} />
          <Route
            path="/vesting/list"
            element={<PrivateRoute component={CreateVesting} />}
          />
          <Route
            path="/vesting/:id"
            element={<PrivateRoute component={EditVesting} />}
          />
          <Route
            path="/chain/list"
            element={<PrivateRoute component={ChainList} />}
          />
          <Route
            path="/token/list"
            element={<PrivateRoute component={TokenList} />}
          />
          <Route
            path="/category/list"
            element={<PrivateRoute component={CategoryList} />}
          />
          <Route
            path="/utility/list"
            element={<PrivateRoute component={UtilityTokenList} />}
          />
          <Route
            path="/utility/:tokenId/list"
            element={<PrivateRoute component={UtilityList} />}
          />
          <Route
            path="/upload/upload-old-vesting"
            element={<PrivateRoute component={UploadOldVesting} />}
          />
          <Route
            path="/upload/upload-vesting-v1"
            element={<PrivateRoute component={UploadVestingV1} />}
          />
          <Route
            path="/upload/upload-vesting-v2"
            element={<PrivateRoute component={UploadVestingV2} />}
          />
          <Route
            path="/token/create"
            element={<PrivateRoute component={CreateToken} />}
          />
          <Route
            path="/token/edit/:id"
            element={<PrivateRoute component={EditToken} />}
          />
          <Route
            path="/tools/gen-vesting"
            element={<PrivateRoute component={GenVesting} />}
          />
          <Route
            path="/subscription-list"
            element={<PrivateRoute component={SubscriptionList} />}
          />
        </Routes>
      </AuthProvider>
    </HistoryRouter>
  )
}

export default App
