import { Button, notification, Popconfirm, Table } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Layout from '../../components/Layout'
import { deleteToken, getTokenList } from '../../services/token.service'
import { truncateAddress } from '../../utils/address'

const TokenList = () => {
  const navigate = useNavigate()

  const [data, setData] = useState([])

  useEffect(() => {
    handleGetTokenList()
  }, [])

  const handleGetTokenList = async () => {
    try {
      const { data } = await getTokenList()

      setData(data.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handlePageCreateToken = () => {
    navigate('/token')
  }

  const handleRemoveToken = async (id: string) => {
    try {
      await deleteToken(id)
      handleGetTokenList()
      notification.success({ message: `delete ${id}` })
    } catch (error) {
      console.error(error)
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <span>{record?.name}</span>,
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      render: (_, record) => <span>{record?.symbol}</span>,
    },
    {
      title: 'Symbol icon',
      dataIndex: 'symbolIcon',
      key: 'symbolIcon',
      render: (_, record) => (
        <img
          src={record?.symbolIcon}
          style={{ width: '32px', height: '32px' }}
          alt={record?.symbol}
        />
      ),
    },
    {
      title: 'Coingecko ID',
      dataIndex: 'coingeckoId',
      key: 'coingeckoId',
      render: (_, record) => (
        <a
          href={`https://www.coingecko.com/en/coins/${record?.coingeckoId}`}
          target="_blank"
          rel="noreferrer"
        >
          {record?.coingeckoId}
        </a>
      ),
    },
    {
      title: 'Total supply',
      dataIndex: 'totalSupply',
      key: 'totalSupply',
      render: (_, record) => <span>{Number(record?.totalSupply)?.toFixed(2)}</span>,
    },
    {
      title: 'Circulating supply',
      dataIndex: 'circulatingSupply',
      key: 'circulatingSupply',
      render: (_, record) => (
        <span>{Number(record?.circulatingSupply)?.toFixed(2)}</span>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (_, record) => <span>{Number(record?.price)?.toFixed(2)}</span>,
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      render: (_, record) => <span>{record?.remark || '-'}</span>,
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => <a href={`/token/edit/${record?.coingeckoId}`}>Edit</a>,
    },
    {
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      render: (_, record) => {
        return (
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => handleRemoveToken(record?.id)}
            okText="Yes"
            cancelText="No"
          >
            <span
              style={{
                marginRight: '8px',
                color: 'red',
                cursor: 'pointer',
              }}
            >
              Delete
            </span>
          </Popconfirm>
        )
      },
    },
  ]

  return (
    <>
      <Layout>
        <div
          style={{
            maxWidth: '1500px',
            width: '100%',
            margin: '0 auto',
            padding: '32px 64px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <Button type="primary" onClick={handlePageCreateToken}>
              Create Token
            </Button>
          </div>

          <div style={{ margin: '32px 0' }}>
            <Table
              columns={columns}
              scroll={{ x: 1500 }}
              dataSource={data}
              rowKey="id"
              pagination={{ pageSize: 20 }}
            />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default TokenList
