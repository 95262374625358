import captureFetch from '../utils/captureFetch'
import fetch from '../utils/fetch'

export const createVesting = (payload: any) => {
  let method = 'POST'
  let path = `vesting/private/create`
  return fetch(method, path, payload)
}

export const getVestingList = () => {
  let method = 'GET'
  let path = `vesting/all`
  return fetch(method, path)
}

export const getVestingById = (id: string) => {
  let method = 'GET'
  let path = `vesting/tokenId/${id}`
  return fetch(method, path)
}

export const createVestingRelease = (payload: any) => {
  let method = 'POST'
  let path = `vesting/release`
  return fetch(method, path, payload)
}

export const deleteVestingRelease = (id: string) => {
  let method = 'DELETE'
  let path = `vesting/release/${id}`
  return fetch(method, path)
}

export const updateVesting = (id: string, payload: any) => {
  let method = 'PATCH'
  let path = `vesting/${id}`
  return fetch(method, path, payload)
}

export const clearFrontendDataCache = () => {
  let method = 'DELETE'
  let path = `vesting/clear-cache`
  return fetch(method, path)
}

export const deleteVesting = (id: string) => {
  let method = 'DELETE'
  let path = `vesting/${id}`
  return fetch(method, path)
}

export const getVestingSubscriptionList = () => {
  let method = 'GET'
  let path = `vesting/subscription-list`
  return fetch(method, path)
}

export const generatePreviewData = (payload: any) => {
  let method = 'POST'
  let path = `chart/preview`
  return captureFetch(method, path, payload, { timeout: 1200000 })
}

export const generateChartData = (coingeckoId: string, onchain?: boolean) => {
  let method = 'GET'

  let path = onchain
    ? `chart/v2/cloudtask/vesting/onchain/${coingeckoId}?extendMonths=3`
    : `chart/v2/cloudtask/vesting/whitepaper/${coingeckoId}?extendMonths=3`
  return captureFetch(method, path)
}
