import { Button, notification, Popconfirm, Table } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Layout from '../../components/Layout'
import { getChainList } from '../../services/chain.service'

import { truncateAddress } from '../../utils/address'

const ChainList = () => {
  const navigate = useNavigate()

  const [data, setData] = useState([])

  useEffect(() => {
    handleGetTokenList()
  }, [])

  const handleGetTokenList = async () => {
    try {
      const { data } = await getChainList()

      setData(data.data)
    } catch (error) {
      console.error(error)
    }
  }

  const columns = [
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      render: (_, record) => (
        <img
          src={record?.icon}
          style={{ width: '32px', height: '32px' }}
          alt={record?.name}
        />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <span>{record?.name}</span>,
    },

    {
      title: 'Chain ID',
      dataIndex: 'chainId',
      key: 'chainId',
      render: (_, record) => <span>{record?.id}</span>,
    },

    {
      title: 'Explorer',
      dataIndex: 'explorer',
      key: 'explorer',
      render: (_, record) => (
        <a href={record?.explorerUrl} target="_blank" rel="noreferrer">
          {record?.explorerUrl ?? '-'}
        </a>
      ),
    },
    {
      title: 'Average Blocktime',
      dataIndex: 'avgblock',
      key: 'avgblock',
      render: (_, record) => <span>{record?.avgBlocktime ?? '-'}</span>,
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => <a href={`/chain/${record?.id}`}>Edit</a>,
    },
  ]

  return (
    <>
      <Layout>
        <div
          style={{
            maxWidth: '1500px',
            width: '100%',
            margin: '0 auto',
            padding: '32px 64px',
          }}
        >
          <div style={{ margin: '32px 0' }}>
            <Table
              columns={columns}
              scroll={{ x: 1500 }}
              dataSource={data}
              rowKey="id"
              pagination={{ pageSize: 20 }}
            />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ChainList
