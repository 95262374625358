import { MenuOutlined } from '@ant-design/icons'
import { Button, notification, Space, Table } from 'antd'
import { arrayMoveImmutable } from 'array-move'
import { useCallback, useEffect, useState } from 'react'
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
  SortEnd,
} from 'react-sortable-hoc'

import Layout from '../../components/Layout'
import {
  getCategoryList,
  getTokenListByCategory,
  updateCategoryPriority,
} from '../../services/category.service'
import CategoryCreateDrawer from './CategoryCreateDrawer'
import CategoryEditDrawer from './CategoryEditDrawer'
import CategoryTokensDrawer from './CategoryTokensDrawer'

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
))

const SortableItem = SortableElement(
  (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />
)

const SortableBody = SortableContainer(
  (props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />
)

interface ICategory extends Record<string, string | number> {
  name: string
  priority: number
}

const CategoryList = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState<boolean>(true)
  const [openCreate, setOpenCreate] = useState<boolean>(false)
  const [openEdit, setOpenEdit] = useState<ICategory | false>(false)
  const [openTokens, setOpenTokens] = useState<ICategory | false>(false)

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <span>{record?.name}</span>,
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      render: (_, record) => <span>{record?.count}</span>,
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      render: (_, record) => <span>{record?.priority}</span>,
    },
    {
      title: '',
      dataIndex: 'option',
      key: 'option',
      render: (_, record) => (
        <Space size="small">
          <Button type="primary" onClick={() => setOpenEdit(record)}>
            Edit
          </Button>
          <Button type="primary" onClick={() => setOpenTokens(record)}>
            Tokens
          </Button>
        </Space>
      ),
    },
  ]

  const onCloseCreate = () => {
    setOpenCreate(false)
    handleGetCategoryList()
  }

  const onCloseEdit = () => {
    setOpenEdit(false)
    handleGetCategoryList()
  }

  const onCloseTokens = () => {
    setOpenTokens(false)
    handleGetCategoryList()
  }

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(data.slice(), oldIndex, newIndex).filter(
        (el) => !!el
      )
      setData(newData)
    }
  }

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  )

  const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
    const index = data.findIndex((x) => x.id === restProps['data-row-key'])
    return <SortableItem index={index} {...restProps} />
  }

  const handleGetCategoryList = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await getCategoryList()

      const newData = []

      for await (const category of data.data) {
        const { data: tokens } = await getTokenListByCategory(category.id)
        newData.push({ ...category, count: tokens.data.length })
      }

      setData(newData)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }, [])

  const handleSave = useCallback(async () => {
    try {
      const payload = {
        data: data.map((category) => {
          return category.id
        }),
      }
      await updateCategoryPriority(payload)
      notification.success({ message: `Saved` })
    } catch (error) {
      notification.error({ message: 'Something went wrong' })
    }
    handleGetCategoryList()
  }, [data, handleGetCategoryList])

  useEffect(() => {
    handleGetCategoryList()
  }, [handleGetCategoryList])

  return (
    <>
      <Layout>
        <div
          style={{
            maxWidth: '1500px',
            width: '100%',
            margin: '0 auto',
            padding: '32px 64px',
          }}
        >
          <Space size="small">
            <Button type="primary" onClick={() => setOpenCreate(true)}>
              Create category
            </Button>
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
          </Space>

          <div style={{ margin: '32px 0' }}>
            <Table
              loading={loading}
              pagination={false}
              dataSource={data}
              columns={columns}
              rowKey="id"
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
            />
          </div>
        </div>
      </Layout>
      <CategoryCreateDrawer visible={openCreate} onClose={onCloseCreate} />
      <CategoryEditDrawer
        visible={!!openEdit}
        onClose={onCloseEdit}
        values={!!openEdit && openEdit}
      />
      <CategoryTokensDrawer
        visible={!!openTokens}
        onClose={onCloseTokens}
        values={!!openTokens && openTokens}
      />
    </>
  )
}

export default CategoryList
