import { useEffect, useState } from 'react'
import { Button, Form, Input, notification } from 'antd'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { IUser, IUserDecode } from '../interfaces/user.interface'
import { socialSignIn } from '../services/auth.service'
import { useStore } from '../stores'

const Login = () => {
  const navigate = useNavigate()
  const { userStore } = useStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const win: any = window
    win?.google?.accounts?.id?.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIEND_ID,
      callback: handleCallback,
    })
    win?.google?.accounts?.id?.renderButton(
      document.getElementById('google-button'),
      {
        theme: 'outline',
        size: 'large',
      }
    )
  }, [typeof window])

  const handleCallback = async (callbackData) => {
    setIsLoading(true)
    const user: IUserDecode = jwt_decode(callbackData.credential)
    //call api authen

    const payload = {
      email: user.email,
      provider: 'google',
      providerUserId: user.sub,
      firstName: user.given_name,
      lastName: user.family_name,
    }

    const {
      data: { data },
    } = await socialSignIn(payload)
    //if user role admin // go pass
    if (['outsource', 'admin'].includes(data.user.role)) {
      notification['success']({
        message: 'Login Success',
        description: `Hi!, ${data.user.firstName} ${data.user.lastName}`,
      })
      navigate('/')
      userStore.setUser(data.user)
      sessionStorage.setItem('credential', data.accessToken)
      setIsLoading(false)
    } else {
      //popup
      notification['error']({
        message: 'Permission denied!',
        description: 'Could not perform this operation.',
      })
      setIsLoading(false)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        padding: '0 32px',
      }}
    >
      <div style={{ width: '300px' }}>
        <h1 style={{ textAlign: 'center' }}>DAOSurv Backoffice</h1>
        <div
          id="google-button"
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      </div>
    </div>
  )
}

export default Login
