import { Form, Input, Modal, ModalFuncProps, notification } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import {
  getUtilityByTokenId,
  updateUtility,
} from '../../../services/utility.service'

interface UtilityCreateModalProps extends ModalFuncProps {
  utilityId: string
}

const UtilityEditModal = (props: UtilityCreateModalProps) => {
  const { utilityId, onOk, onCancel, ...rest } = props
  const [form] = Form.useForm()
  const { tokenId } = useParams()

  const handleGetUtilityToken = useCallback(async () => {
    try {
      const { data } = await getUtilityByTokenId(tokenId)

      const utility = data.data.find((utility) => utility.id === utilityId)

      form.setFieldsValue({
        tokenId: utility.tokenId,
        name: utility.name,
        description: utility.description,
        url: utility.url,
        icon: utility.icon,
        disclaimer: utility.disclaimer,
      })
    } catch (error) {
      console.error(error)
    }
  }, [form, tokenId, utilityId])

  const onFinish = async (values: any) => {
    try {
      const payload = {
        ...values,
      }

      await updateUtility(utilityId, payload)
      notification.success({ message: `update ${form.getFieldValue('name')}` })
      onOk()
    } catch (error) {
      notification.error({ message: 'Something went wrong' })
    }
  }

  useEffect(() => {
    handleGetUtilityToken()
  }, [handleGetUtilityToken])

  return (
    <>
      <Modal
        title="Edit Utility"
        onOk={() => {
          form.submit()
        }}
        onCancel={onCancel}
        {...rest}
      >
        <div>
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              label="Token Id"
              name="tokenId"
              rules={[{ required: true, message: 'Please input your tokenId!' }]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Please input your description!' }]}
            >
              <TextArea rows={2} />
            </Form.Item>

            <Form.Item
              label="Url"
              name="url"
              rules={[{ required: true, message: 'Please input your url!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Icon"
              name="icon"
              rules={[{ required: true, message: 'Please input your Icon!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Disclaimer"
              name="disclaimer"
              rules={[{ message: 'Please input your disclaimer!' }]}
            >
              <TextArea rows={2} />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  )
}

export default UtilityEditModal
