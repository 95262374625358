export const worksheetConfig = {
  labelIndex: 18,
  vestingStartIndex: 1,
  allocationStartIndex: 1,
  rolesStartIndex: 1,
  refsStartIndex: 1,
  token: {
    description: 10,
  },
}

export const v1Template = {
  general: {
    name: 1,
    symbol: 2,
    chainId: 3,
    address: 4,
    coingeckoId: 5,
    description: 6,
    emissionType: 13,
    supplyChartDateForWp: 14,
    supplyChartDateForOnChain: 15,
    predictYear: 16,
    predictSupply: 17,
  },
  vestingWp: {
    start: 18,
    cols: {
      label: 0,
      allocation: 1,
      address: 2,
      registerType: 3,
      walletType: 4,
      supplyType: 5,
      vestingType: 6,
      amount: 7,
      remark: 8,
      tx: 9,
      reference: 10,
      chainId: 11,
    },
  },
  vestingTracked: {
    start: 60,
    cols: {
      label: 0,
      allocation: 1,
      address: 2,
      registerType: 3,
      walletType: 4,
      supplyType: 5,
      vestingType: 6,
      amount: 7,
      remark: 8,
      tx: 9,
      reference: 10,
      chainId: 11,
    },
  },
  allocationWp: {
    start: 1,
    cols: {
      title: 0,
      amount: 1,
      color: 2,
      groupIndex: 3,
      chartIndex: 4,
    },
  },
  allocationTracked: {
    start: 1,
    cols: {
      title: 0,
      amount: 1,
      color: 2,
      groupIndex: 3,
      chartIndex: 4,
    },
  },
  roles: {
    start: 1,
    cols: {
      address: 0,
      vesting: 1,
      role: 2,
      label: 3,
    },
  },
  reference: {
    start: 1,
    cols: {
      label: 0,
      link: 1,
      type: 2,
    },
  },
  vestingCardWP: {
    start: 1,
    cols: {
      vesting: 0,
      startDate: 1,
      endDate: 2,
      startBlock: 3,
      endBlock: 4,
      startAmount: 5,
      endAmount: 6,
    },
  },
  vestingCardTracked: {
    start: 1,
    cols: {
      vesting: 0,
      startDate: 1,
      endDate: 2,
      startBlock: 3,
      endBlock: 4,
      startAmount: 5,
      endAmount: 6,
    },
  },
}

export const v2Template = {
  general: {
    name: 1,
    symbol: 2,
    chainId: 3,
    address: 4,
    coingeckoId: 5,
    description: 6,
    emissionType: 13,
    supplyChartDateForWp: 14,
    supplyChartDateForOnChain: 15,
    predictYear: 16,
    predictSupply: 17,
  },
  vestingWp: {
    start: 26,
    cols: {
      label: 0,
      allocation: 1,
      address: 2,
      registerType: 3,
      walletType: 4,
      supplyType: 5,
      vestingType: 6,
      amount: 7,
      remark: 8,
      tx: 9,
      reference: 10,
      chainId: 11,
    },
  },
  vestingTracked: {
    start: 60,
    cols: {
      label: 0,
      allocation: 1,
      address: 2,
      registerType: 3,
      walletType: 4,
      supplyType: 5,
      vestingType: 6,
      amount: 7,
      remark: 8,
      tx: 9,
      reference: 10,
      chainId: 11,
    },
  },
  allocationWp: {
    start: 1,
    cols: {
      title: 0,
      amount: 1,
      color: 2,
      groupIndex: 3,
      chartIndex: 4,
      percentage: 7,
      description: 8,
    },
  },
  allocationTracked: {
    start: 1,
    cols: {
      title: 0,
      amount: 1,
      color: 2,
      groupIndex: 3,
      chartIndex: 4,
      percentage: 7,
      description: 8,
    },
  },
  roles: {
    start: 1,
    cols: {
      address: 0,
      vesting: 1,
      role: 2,
      label: 3,
    },
  },
  reference: {
    start: 1,
    cols: {
      label: 0,
      link: 1,
      type: 2,
    },
  },
  vestingCardWP: {
    start: 1,
    cols: {
      vesting: 0,
      startDate: 1,
      endDate: 2,
      startBlock: 3,
      endBlock: 4,
      startAmount: 5,
      endAmount: 6,
    },
  },
  vestingCardTracked: {
    start: 1,
    cols: {
      vesting: 0,
      startDate: 1,
      endDate: 2,
      startBlock: 3,
      endBlock: 4,
      startAmount: 5,
      endAmount: 6,
    },
  },
}
